import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import "./styles.css";
import { IonHeader, IonImg } from "@ionic/react";
import { setTheme } from "../../theme/themes";
import { keepTheme } from "../../theme/themes";
import { Images } from "../../Images";
import { FaRegUser } from "react-icons/fa6";
import { FiShare2 } from "react-icons/fi";
import { MdLockReset, MdOutlineSettings } from "react-icons/md";
import { BiMoon } from "react-icons/bi";
import { LiaUserEditSolid } from "react-icons/lia";
import { IoMdLogOut } from "react-icons/io";

function Header(props) {
  const history = useHistory();
  const {
    loginModalOpen,
    openModal,
    handleResetPassword,
    handleReferModal,
    setLoginPopupOpen,
    activeUserDropdown,
    setActiveUserDropdown,
    handleActiveUserDropdown,
  } = props;
  const [isDarkmode, setDarkmode] = useState(
    localStorage.getItem("theme") === null
      ? "dark-mode"
      : localStorage.getItem("theme")
  );
  const MENU = [
    // {
    //   tab: "login",
    //   img: <FaRegUser />,
    // },
    {
      tab: "Darkmode",
      img: <BiMoon />,
    },
    {
      tab: "Settings",
      img: <MdOutlineSettings />,
    },
    {
      tab: "Share",
      img: <FiShare2 />,
    },
  ];
  // const [activeUserDropdown, setActiveUserDropdown] = useState(false);
  // const handleActiveUserDropdown = () => {
  //   setActiveUserDropdown((prev) => !prev);
  // };

  const handleLogin = () => {
    setLoginPopupOpen(true);
  };

  const weTwoCallMenu = useRef(null);
  // const closeActiveDropdown = (e) => {
  //   if (
  //     weTwoCallMenu.current &&
  //     activeUserDropdown &&
  //     !weTwoCallMenu.current.contains(e.target)
  //   ) {
  //     setActiveUserDropdown(false);
  //   }
  // };
  // document.addEventListener("mousedown", closeActiveDropdown);

  const handleDarkLite = () => {
    setActiveUserDropdown(false);
    setDarkmode(
      localStorage.getItem("theme") === "dark-mode" ? "lite-mode" : "dark-mode"
    );
  };

  const theme = isDarkmode === "dark-mode" ? "dark-mode" : "lite-mode";

  setTheme(theme, theme == "dark-mode" ? "lite-mode" : "dark-mode");
  const handleShareModal = () => {
    handleReferModal();
    setActiveUserDropdown(false);
  };
  const handleMenuitem = (index) => {
    {
      index === 1 && handleActiveUserDropdown();
    }
    {
      index === 0 && handleDarkLite();
    }
    {
      index === 2 && handleShareModal();
    }
  };

  const handleLogout = () => {
    localStorage?.clear();
    window.location.reload("");
  };
  return (
    <IonHeader ref={weTwoCallMenu}>
      <div className={`user-outer-div ${loginModalOpen ? "modal-open" : ""}`}>
        {/* // <div className={`header-container ${loginModalOpen ? "modal-open" : ""}`}> */}
        {/* // <IonHeader className="user-outer-div" ref={weTwoCallMenu}> */}

        {!openModal && !loginModalOpen && (
          <>
            
            <div className="flex-jc-c">
              <IonImg
                className="custom-ion-img"
                alt="main-logo"
                src={
                  isDarkmode === "dark-mode"
                    ? Images?.We2callDark
                    : Images?.We2CallLight
                }
                onClick={() => handleLogin()}
              />
            </div>
            <div className="header-right">
              {MENU.map((menuItem, index) => {
                return (
                  <div key={index} onClick={() => handleMenuitem(index)}>
                    <div className="custom-ionicon-container flex-center mar-5">
                      {menuItem.img}
                    </div>
                  </div>
                );
              })}
              {activeUserDropdown && (
                <div className="flex-column user-home-dropdown-container user-dropdown-container">
                  <div
                    className="d-flex sub-dropdown-container"
                    onClick={() => history.push("/editprofile")}
                  >
                    <div className="sub-dropdown-image flex-center">
                      <LiaUserEditSolid />
                    </div>
                    <span className="dropdown-title">Edit profile</span>
                  </div>
                  <div
                    className="d-flex sub-dropdown-container mt-10"
                    onClick={handleResetPassword}
                  >
                    <div className="sub-dropdown-image flex-center">
                      <MdLockReset />
                    </div>
                    <span className="dropdown-title">Reset Password</span>
                  </div>
                  <div
                    className="d-flex sub-dropdown-container mt-10"
                    onClick={() => handleLogout()}
                  >
                    <div className="sub-dropdown-image flex-center">
                      <IoMdLogOut />
                    </div>
                    <span className="dropdown-title">Logout</span>
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </IonHeader>
  );
}

export default Header;
