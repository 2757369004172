import {
  GET_USER_DATA,
  LOGIN_MODAL,
  SIGN_UP_USER,
  SET_MAXMIZE,
  SET_MEETING_IS_STARTED,
  SET_MINIMIZE,
  LIVE_MEETING,
} from "./constants";

export const getUserData = (userPayload) => ({
  type: GET_USER_DATA,
  payload: userPayload,
});

export const setSignUpData = (signUpData) => ({
  type: SIGN_UP_USER,
  payload: signUpData,
});
export const openLoginModal = (status) => ({
  type: LOGIN_MODAL,
  payload: status,
});

export const setMeetingStartStatus = (data) => {
  return {
    type: SET_MEETING_IS_STARTED,
    payloda: data,
  };
};

export const setMinimize = (data) => {
  return {
    type: SET_MINIMIZE,
    payload: data,
  };
};
export const setMaxmize = (data) => {
  return {
    type: SET_MAXMIZE,
    payload: data,
  };
};

export const liveMeeting = (data) => {
  return {
    type: LIVE_MEETING,
    payload: data,
  };
};
