import { IonApp, setupIonicReact, isPlatform } from "@ionic/react";
import React, { useEffect } from "react";
import Routes from "./routes";
import { IonReactRouter } from "@ionic/react-router";
import "@ionic/react/css/core.css";
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";
import "@ionic/react/css/display.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/padding.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "./theme/variables.css";

setupIonicReact();

const App: React.FC = () => {
  useEffect(() => {
    redirectToAppropriateView();
  }, []);

  const redirectToAppropriateView = () => {
    if (isPlatform("mobile")) {
    } else {
      window.location.href = "https://web.we2call.com";
    }
  };
  return (
    <>
    {isPlatform("mobile") ?  (
      <IonApp>
      <IonReactRouter>
        <Routes />
      </IonReactRouter>
    </IonApp>
    ) : null}
    </>
  );
};

export default App;
