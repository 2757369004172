import React from "react";
import { IonModal } from "@ionic/react";
import { RxCross1 } from "react-icons/rx";
import "./styles.css";
import { useHistory } from "react-router";
function ThankYouPopup(props) {
  const { openThankyouPopup, setOpenThankYouPopup } = props;
  const handleThankyouPopupClose = () => {
    setOpenThankYouPopup(false);
  };
  const history = useHistory();
  return (
    <IonModal className="w-80 fancy-declare-popup" isOpen={openThankyouPopup}>
      <div
        className="align-items-center flex-column custom-box-shadow fancy-declare-div"
        onClick={handleThankyouPopupClose}
      >
        <div className="w-100 flex-flex-end p-5">
          <RxCross1 />
        </div>
        <div>
          <img
            className="fancy-declare-image"
            src={process.env.PUBLIC_URL + "./assets/images/thumbsup.png"}
            alt="Declare_Man_Img"
          />
        </div>
        <div className="w-75 fancy-declare-text mt-10">
          Your Profile Successfully Updated
        </div>
        <div
          className="mt-15 d-flex justify-content-around w-100 mb-10"
          onClick={() => history.push("/")}
        >
          <button className="w-40 button-box-shadow fancy-declare-button clr-white">
            Go Back Home
          </button>
        </div>
      </div>
    </IonModal>
  );
}

export default ThankYouPopup;
