import "./styles.css";
import { IonHeader, IonImg } from "@ionic/react";
import { useHistory } from "react-router";
import { Images } from "../Images";
import { FaAngleLeft } from "react-icons/fa6";

function ToursHeader(props) {
  const { heading } = props;
  const history = useHistory();
  return (
    <IonHeader className="w-100 user-outer-div">
      <div className="flex-space-between w-96">
        <div className="flex-center">
          <div
            className="flex-center custom-ionicon-container custom-back-icon"
            onClick={() => history.push("/")}
          >
            <FaAngleLeft className="font-25" />
          </div>
          <div className="meetings-heading ml-5">{heading}</div>
        </div>
        {heading === "Offers" ? null : (
          <div
            className="flex-center view-offers-div button-box-shadow"
            onClick={() => history.push("/offers")}
          >
            <img
              className="d-flex discount-image"
              src={Images.DiscountIcon}
              alt="Discount"
            />
            <div className="font-14 fw-600">View Offers</div>
          </div>
        )}
      </div>
      <hr className="horizantal-line" />
    </IonHeader>
  );
}

export default ToursHeader;
