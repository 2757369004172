import { IonCol, IonRow } from "@ionic/react";
import { useState } from "react";
import "./styles.css";
import {
  MdMicNone,
  MdMicOff,
  MdOutlineFeaturedVideo,
  MdVideocamOff,
  MdArrowBackIosNew,
} from "react-icons/md";
import { FiVideo } from "react-icons/fi";
import { IoChatbubbleEllipsesOutline } from "react-icons/io5";
import { ImPhoneHangUp } from "react-icons/im";
import { HiDotsHorizontal } from "react-icons/hi";
import { useHistory } from "react-router";
import { FaRegUser } from "react-icons/fa";

function UserCallingScreen() {
  const history = useHistory();
  const [isMicOn, setIsMicOn] = useState(true);
  const [isVideoOn, setIsVideoOn] = useState(true);

  const toggleMic = () => {
    setIsMicOn((prevState) => !prevState);
  };

  const toggleVideo = () => {
    setIsVideoOn((prevState) => !prevState);
  };
  return (
    <div className="h-100vh call-bg w-100">
      <div className="d-flex justify-content-center align-items-center flex-column p-10 fw-600">
        <div className="footer-bg calling-video-border h-65vh w-95 br-20 d-flex flex-column align-items-center p-5">
          <div className="w-100 d-flex justify-content-between font-14">
            <div className="h-max yellow-btn p-5 br-10 d-flex">
              <FaRegUser className="d-flex mr-5" />
              <span>SuperAdmin2000</span>
            </div>
            <div className="w-60 footer-bg calling-video-border br-20 flex-column align-items-center  p-10">
              <div className="w-100">
                <div className="d-flex blue-bg2 p-5 br-10 w-max">
                  <FaRegUser className="d-flex mr-5" />
                  <span>Master</span>
                </div>
              </div>
              <div className="flex-center circle-div2">
                <span>U</span>
              </div>
              <div className="w-100 d-flex justify-content-end">
                <div className="w-max br-5 call-bg mute-pad flex-center">
                  <MdMicNone className="font-20 clr-white" />
                </div>
              </div>
            </div>
          </div>
          <div className="flex-center circle-div calling-video-bg mt-5rem">
            <span>C</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserCallingScreen;
