import { useEffect, useRef, useState } from "react";
import { IoCloseSharp } from "react-icons/io5";
import "./styles.css";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
import { Images } from "../../Images";
import { useIonToast, IonModal } from "@ionic/react";
import { call } from "../../api/axios";
import axios from "axios";
import { useDispatch } from "react-redux";
import { setSignUpData } from "../../redux/action";
import { SIGNUP_USER_API } from "../../api/endpoints";

const SignIn = (props) => {
  let register_id = localStorage?.getItem("register_id");
  const [isProcessing, setIsProcessing] = useState(false);
  const { handleOpenModal, openModal } = props;
  const [showPassword, setShowPassword] = useState(false);
  const [showRePassword, setShowRePassword] = useState(true);
  const [otp, setOTP] = useState([""]);
  const otpInput = [{}, {}, {}, {}, {}, {}];
  const [showOtpInput, setShowOtpInput] = useState(false);
  const [activeHeading, setActiveHeading] = useState(0);
  const [inputData, setInputData] = useState({
    register_id: register_id,
  });
  const [allCountries, setAllCountries] = useState([]);
  const [country, setCountry] = useState("");
  const [countryDropdown, setCountryDropdown] = useState(false);

  const inputRefs = useRef([]);
  const dispatch = useDispatch();
  const handleOTPChange = (index, event) => {
    const otpvalue = event.target.value;
    const nextIndex = index + 1;
    inputRefs.current[index] = otpvalue;
    setOTP(otpvalue);

    if (otpvalue && nextIndex < inputRefs.current.length) {
      inputRefs.current[nextIndex].focus();
    }
  };
  const handleShowOtpIpnut = (e) => {
    e.preventDefault();
    setShowOtpInput(true);
  };
  const handleSubmit = () => {};
  const handleHeadings = (index) => {
    setActiveHeading(index);
    setShowOtpInput(false);
  };
  const handleShowPassword = (value) => {
    if (value === "password") {
      setShowPassword((prev) => !prev);
    } else {
      setShowRePassword((prev) => !prev);
    }
  };
  const getAllCounties = async () => {
    axios({
      method: "get",
      url: "https://trial.mobiscroll.com/content/countries.json",
      mode: "cors",
      redirect: "follow",
      referrerPolicy: "no-referrer",
      timeout: 60000,
    })
      .then((resp) => {
        const countries = [];
        for (let i = 0; i < resp.data?.length; ++i) {
          const country = resp.data?.[i];
          countries.push({ text: country.text, value: country.value });
        }
        setAllCountries(countries);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getAllCounties();
  }, []);
  const onInputChange = (e) => {
    setInputData({ ...inputData, [e.target.name]: e.target.value });
  };
  const onOptionClick = (obj) => {
    setInputData({ ...inputData, country: obj.value });
    setCountry(obj.text);
    setCountryDropdown(!countryDropdown);
  };
  const onVerifyClick = async () => {
    if (!inputData.referral_code) {
      return presentToast("Admin ID is Required");
    }
    if (!inputData.password) {
      return presentToast("Password is Required");
    }
    if (!inputData.user_name) {
      return presentToast("Usre Name is Required");
    }
    if (inputData.password !== inputData.confirmPassword) {
      return presentToast("Confirm Password is Wrong");
    } else {
      dispatch(setSignUpData(inputData));
      setIsProcessing(true);
      await call(SIGNUP_USER_API, inputData)
        .then((res) => {
          setIsProcessing(false);
        })
        .catch((err) => {
          setIsProcessing(false);
        });
      setActiveHeading(0);
    }
  };

  const [present] = useIonToast();

  const presentToast = (message) => {
    present({
      message: message,
      duration: 1500,
      position: "middle",
      color: "danger",
    });
  };

  const headings = ["Signup", "By Phone", "By Email"];
  return (
    <IonModal id="signup-modal" isOpen={openModal}>
      <div className="w-100 d-flex-jc-end">
        <IoCloseSharp className="close-icon" onClick={handleOpenModal} />
      </div>
      <div className="flex-jc-sb mt-10">
        <div className="signup-label">Signup</div>
        <img className="custom-w2c-img" src={Images.We2callDark}></img>
      </div>
      <div className="flex-jc-sb mt-10 mb-10 font-14">
        {headings.map((value, index) => (
          <div
            key={index}
            className={
              activeHeading === index
                ? "flex-jc-c signup-active-headings font-14"
                : "flex-jc-c signup-headings font-14"
            }
          >
            {value}
          </div>
        ))}
      </div>
      <form onClick={(e) => e.preventDefault()}>
        {activeHeading === 0 && (
          <div>
            <div className="flex-jc-sb email-input mt-10 w-100 font-14">
              <input
                placeholder="Enter Your Admin ID"
                onChange={(e) => onInputChange(e)}
                value={inputData.admin_code}
                type="text"
                name="referral_code"
                className="email-input-tag font-14"
              />
            </div>
            <div className="flex-jc-sb email-input mt-10 w-100 font-14">
              <input
                placeholder="Enter Your Refferal ID"
                onChange={(e) => onInputChange(e)}
                value={inputData.referral_code}
                type="text"
                name="referral_code"
                className="email-input-tag font-14"
              />
            </div>
            <div className="flex-jc-sb email-input mt-10 w-100 font-14">
              <div className="email-input-tag w-100 font-14">
                <div className="flex-jc-sb email-input w-100 font-14">
                  <input
                    placeholder="Select Country"
                    onClick={() => setCountryDropdown(!countryDropdown)}
                    value={country}
                    type="text"
                    name="country"
                    className="email-input-tag font-14"
                  />
                </div>
                {countryDropdown && (
                  <div className="drop-down-container">
                    {allCountries?.map((obj) => (
                      <div
                        value={obj.value}
                        className="drop-down-options"
                        onClick={() => onOptionClick(obj)}
                      >
                        <img
                          className="md-country-picker-flag"
                          height="18"
                          width="30"
                          src={
                            "https://img.mobiscroll.com/demos/flags/" +
                            obj.value +
                            ".png"
                          }
                          alt="Flag"
                        />
                        {obj.text}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
            <div className="flex-jc-sb email-input mt-10 w-100 font-14">
              <input
                placeholder="User Name"
                type="text"
                onChange={(e) => onInputChange(e)}
                value={inputData.user_name}
                name="user_name"
                className="email-input-tag font-14"
              />
            </div>
            <div className="flex-jc-sb email-input mt-10 w-100 font-14">
              <input
                placeholder="Enter Password"
                type={showPassword ? "text" : "password"}
                value={inputData.assword}
                onChange={(e) => onInputChange(e)}
                name="password"
                className="email-input-tag"
              />
              <i onClick={() => handleShowPassword("password")}>
                {showPassword ? (
                  <AiFillEye className="close-icon mr-10" />
                ) : (
                  <AiFillEyeInvisible className="close-icon mr-10" />
                )}
              </i>
            </div>
            <div className="flex-jc-sb email-input mt-10 w-100 font-14">
              <input
                placeholder="Re Enter Password"
                type={showRePassword ? "text" : "password"}
                className="email-input-tag"
                name="confirmPassword"
                value={inputData.confirmPassword}
                onChange={(e) => onInputChange(e)}
              />
              <i onClick={() => handleShowPassword("re-password")}>
                {showRePassword ? (
                  <AiFillEye className="close-icon mr-10" />
                ) : (
                  <AiFillEyeInvisible className="close-icon mr-10" />
                )}
              </i>
            </div>
          </div>
        )}
        {activeHeading === 1 && (
          <div className="flex">
            <select className="select-options email-input mt-10 w-20 mr-10 font-14">
              <option>+91</option>
            </select>
            <input
              placeholder="Enter Phone Number"
              type="number"
              name="mobile_number"
              value={inputData.mobile_number}
              onChange={(e) => onInputChange(e)}
              className="email-input pl-10 mt-10 w-80 font-14"
            />
          </div>
        )}
        {activeHeading === 2 && (
          <input
            placeholder="Enter Mail ID"
            type="email"
            name="email"
            value={inputData.email}
            onChange={(e) => onInputChange(e)}
            className="email-input pl-10 mt-10 w-100 font-14"
          />
        )}
        {activeHeading === 0 && (
          <button
            className="otp-button custom-box-shadow mt-10 clr-grn font-14"
            onClick={onVerifyClick}
            disabled={isProcessing}
          >
            Verify
          </button>
        )}

        {showOtpInput && (
          <center>
            <hr />
            <div className="flex mt-10">
              {otpInput.map((digit, index) => (
                <input
                  className="flex-jc-c otp-inputs font-14"
                  key={index}
                  type="number"
                  inputMode="numeric"
                  ref={(el) => (inputRefs.current[index] = el)}
                  onChange={(event) => handleOTPChange(index, event)}
                  length={1}
                />
              ))}
            </div>
            <button
              className="otp-button custom-box-shadow mt-10 clr-grn font-14"
              onClick={handleSubmit}
            >
              Submit
            </button>
          </center>
        )}
      </form>
    </IonModal>
  );
};

export default SignIn;
