import { IonImg } from "@ionic/react";
import { Images } from "../../Images";

function NoChats() {
  return (
    <div>
      {/* <ChatsHeader /> */}
      <div className="flex-jc-c flex-column">
        <IonImg className="no-chat-image" src={Images.NoChatImage}></IonImg>
        <div className="chat-meetings-heading mt-20 ">You have not received any message yet!!!</div>
      </div>
    </div>
  );
}

export default NoChats;
