function setTheme(themeName, oldTheme) {
  localStorage.setItem("theme", themeName);
  document.documentElement.classList.add(oldTheme);
  document.documentElement.classList.replace(oldTheme, themeName);
}

function keepTheme() {
  if (localStorage.getItem("theme")) {
    switch (localStorage.getItem("theme")) {
      case "dark-mode":
        return setTheme("dark-mode", "lite-mode");
      case "lite-mode":
        return setTheme("lite-mode", "dark-mode");
      default:
        setTheme("dark-mode","lite-mode");
        break;
    }
  }
}

module.exports = {
  setTheme,
  keepTheme,
};
