function HomeTable(props) {
  const { data, columns } = props;
  return (
    <table className="account-settlement-summary account-settlement-summary-data">
      <tbody className="t-body">
        {data.map((item, rowIndex) => (
          <tr key={rowIndex}>
            {columns.map((column, colIndex) => (
              <td key={colIndex} className="table-meet-heading ">
                <div
                  className={
                    item[column.field] === "Join"
                      ? "td-class"
                      : item[column.field] === "Not-Started"
                      ? "not-class"
                      : ""
                  }
                >
                  {item[column.field]}
                </div>
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default HomeTable;
