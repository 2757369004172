import { Images } from "../Images";

function ViewOffers() {
  return (
    <div className="meetings-container w-92 flex-center flex-column h-70vh">
      <img className="gift-box-img" src={Images.TourGiftBox} alt="Gift_Box" />
      <div className="font-16 text-center mt-10">
        We travel not escape life, but for life not escape us Please
        <span className="green-text"> click here </span>
        what you are interested
      </div>
    </div>
  );
}

export default ViewOffers;
