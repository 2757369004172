import { useState, useEffect } from "react";
import { Images } from "../Images";
import coins from "../assets/images/coin.png";
import moneyImage from "../assets/images/money.png";

import MatchPositionTables from "./MatchPositionTables"
import { FaChevronDown, FaChevronUp } from "react-icons/fa6";
import "./styles.css";
import { useDispatch, useSelector } from "react-redux";
// import { setMinimize } from "../../src/redux/actions/dataActions";
import { setMinimize } from "../redux/action";
import { BiSolidChevronLeft } from "react-icons/bi";
import { useHistory } from "react-router";
import { GET_LIVE_MEETING } from "../../src/config/endpoints";
import { call } from "../../src/config/axios";

function MatchPosition(props) {
    const isMinimize = useSelector((State) => State?.data?.isMinimize);
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    const onUserJoinFlag = useSelector((State) => State.data?.onUserJoinClick);
    const isAdminCreated = localStorage.getItem("isAdminMeeting") === "true";
    const history = useHistory();
    const dispatch = useDispatch();
    const [showDownIcon, setShowDownIcon] = useState(true);
    const [liveMeeting, setLiveMeeting] = useState({});
    const [showUpIcon, setShowUpIcon] = useState(false);
    const getLiveMeetingData = async () => {
        const creator_id = isAdminCreated ? localStorage.getItem("register_id") : localStorage.getItem("creator_id");
        return await call(GET_LIVE_MEETING, {
            creator_id: creator_id,
            meeting_id: props.meetingId,
        })
            .then((res) => {
                console.log("liveMeeting", res)
                setLiveMeeting(res.data?.data?.Items?.[0]);
            })
            .catch((err) => {
                throw err;
            });
    };

    useEffect(() => {

        getLiveMeetingData();
    }, [props.meetingId]);
    const handleClickDown = () => {
        setShowDownIcon(false);
        setShowUpIcon(true);
    };
    const handleClickUP = () => {
        setShowDownIcon(true);
        setShowUpIcon(false);
    };
    const onMinimizeClick = () => {
        onUserJoinFlag && dispatch(setMinimize(true));
    };
    const onBackClick = () => {
        onMinimizeClick()
        history.push("/");
    }
    if (isMinimize) return null;
    return (
        <>
            <div>
                {/* <MeetingsHeader heading={"Meetings"} onMinimizeClick={onMinimizeClick} /> */}
                <div className="match-position-header-container">
                    <div className="flex-center" onClick={() => onBackClick()}>
                        <BiSolidChevronLeft
                            className="header-icons custom-back-icon"
                        />
                    </div>
                    <img className="header-images" src={coins} />
                    {/* <img className="header-images" src={Images.coins} /> */}
                    <p>
                        Match Position
                        <br />
                        <span>Check your position</span>
                    </p>
                    {showDownIcon && (
                        <FaChevronDown className="header-icons" onClick={handleClickDown} />
                    )}
                    {showUpIcon && (
                        <FaChevronUp onClick={handleClickUP} className="header-icons" />
                    )}
                    <img className="header-images" src={moneyImage} />
                </div>
                {showUpIcon && (
                    <MatchPositionTables
                        matchId={liveMeeting?.matchId}
                        liveMeeting={liveMeeting}
                    />
                )}
            </div>
        </>

    );
}

export default MatchPosition;
