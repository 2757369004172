import { Redirect, Route, useLocation } from "react-router";
import { useEffect, useState } from "react";
import { keepTheme, setTheme } from "../theme/themes";
import Meetings from "../userPages/meetings/Meetings";
import Home from "../userPages/home/Home";
import Chats from "../userPages/chats/Chats";
import NoChats from "../userPages/chats/NoChats";
import EditProfile from "../userPages/editProfile/EditProfile";
import GroupCall from "../GroupCall";
import Join from "../GroupCall/join";
// import CallMeetings from "../GroupCall/CallMeetings";
import ToursAndTournaments from "../tours-tournaments/ToursAndTournaments";
import Offers from "../tours-tournaments/Offers";
import WaitingJoinScreen from "../VideoCall/WaitingJoinScreen";
import CallingMain from "../VideoCall/CallingMain";
import UserCallingScreen from "../VideoCall/UserCallingScreen";

function Routes() {
  const location = useLocation();
  useEffect(() => {
    keepTheme();
  }, []);
  const isLogin = localStorage.getItem("register_id");
  if (location?.pathname?.includes("/group-call") && isLogin) {
    return (
      <Route path={"/group-call/:id"}
       exact 
        render={(props) => (
        <GroupCall {...props} id={props.match.params.id}  />
        )}
       />
    );
  }

  return (
    <>
      <Route path="/" component={Home} exact></Route>
      {isLogin ? (
        <>
          <Route path="/meetings/:id" component={Meetings} exact></Route>
          <Route path="/group-call/:id" component={GroupCall} exact></Route>
          <Route path="/join/:id" component={Join} exact></Route>
          {/* <Route path="/meeting/:id" component={CallMeetings} exact></Route> */}
          <Route path="/chats" component={Chats} exact></Route>
          <Route path="/nochats" component={NoChats} exact></Route>
          <Route path="/editprofile" component={EditProfile} exact></Route>
          <Route
            path="/tours-and-tournament"
            component={ToursAndTournaments}
            exact
          ></Route>
          <Route path="/offers" component={Offers} exact></Route>
          <Route path="/calling" component={CallingMain} exact></Route>
          <Route path="/waiting" component={WaitingJoinScreen} exact></Route>
        </>
      ) : (
        <Route
          path="*"
          render={() => {
            <Redirect to="/" />;
          }}
        />
      )}
    </>
  );
}

export default Routes;
