import React, { useState } from "react";
import { IonModal } from "@ionic/react";
import { RxCross1 } from "react-icons/rx";
import { IoSend } from "react-icons/io5";

function ChatScreen({ chatPopup, setChatPopup }) {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");

  const handleSend = () => {
    if (newMessage.trim()) {
      const message = {
        text: newMessage,
        timestamp: new Date().toLocaleTimeString(),
        sender: "Ranjith Kumar",
      };
      setMessages([...messages, message]);
      setNewMessage("");
    }
  };

  return (
    <IonModal isOpen={chatPopup} className="w-80 chat-popup">
      <div className="h-fill footer-bg">
        <div className="flex-space-between font-18 p-10">
          <span className="font-30">Chat</span>
          <span>
            <RxCross1 onClick={() => setChatPopup(false)} />
          </span>
        </div>
        <div className="chat-border flex-column p-10">
          {messages.map((msg, index) => (
            <>
              <div key={index} className="send-msg">
                <div className="font-18">{msg.text}</div>
                <span className="font-14">{msg.timestamp}</span>
              </div>
              <div key={index} className="receive-msg">
                <div className="font-18">{msg.text}</div>
                <span className="font-14">{msg.timestamp}</span>
              </div>
            </>
          ))}
        </div>
        <div className="flex-space-between p-10">
          <input
            className="w-75 input-bg-call p-14 br-10 border-none-call clr-white font-16"
            placeholder="Enter Message"
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
          />
          <div
            className="w-20 green-bg flex-center clr-white font-25 p-10 br-10"
            onClick={handleSend}
          >
            <IoSend />
          </div>
        </div>
      </div>
    </IonModal>
  );
}

export default ChatScreen;
