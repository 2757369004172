export const Images = {
  SettingIcon: "./assets/images/Settings.png",
  We2callDark: "./assets/images/we_2_call_logo.png",
  We2CallLight: "/assets/images/we_2_Lite.png",
  We2call: "./assets/images/we_2_call_logo.png",
  WifiIcon: "/assets/images/wifi_icon.png",
  DarkBGImage: "/assets/images/dark_back_ground.png",
  WhiteBGImage: "/assets/images/white_back_ground.png",
  NoMeetingImage: "/assets/images/no_meeting_img.png",
  ViratImage: "./assets/images/virat_image.png",
  ViratImage02: "./assets/images/virat_image_02.png",
  EditIcon: "/assets/images/edit_icon.png",
  callWaiting: "/assets/images/Calling-waiting.png",
  ShareIcon: "./assets/images/share_Icon.png",
  NoChatImage: "./assets/images/no_chat_img.png",
  DhoniImage: "./assets/images/dhoni_image.png",
  DhoniImage02: "./assets/images/dhoni_image_02.png",
  RohitImage: "./assets/images/rohit_image.png",
  BackArrow: "./assets/images/back_arrow.png",
  HomeMainBanner: "./assets/images/home_main_banner.png",
  HeadPhone: "./assets/images/head_phone.png",
  CallIcon: "./assets/images/call_icon.png",
  MessageIcon: " ./assets/images/message_Icon.png",
  Star1: "/assets/images/star1.png",
  Star2: "/assets/images/star1.png",
  Star3: "/assets/images/star1.png",
  CallMen: "/assets/images/call_men.png",
  ResetPassword: "./assets/images/reset_password.png",
  EditProfileIcon: "./assets/images/edit_profile.png",
  LiveIcon: "./assets/images/live_icon.png",
  CompletedColourIcon: "./assets/images/completed_colour_icon.png",
  LoginIcon: "./assets/images/login_icon.png",
  DarkModeColour: "./assets/images/dark_mode_colour.png",
  Settings: "/assets/images/Settings.png",
  Share: "./assets/images/share.png",
  ChatIcon: "./assets/images/chat_icon.png",
  Home: "./assets/images/home.png",
  Meetings: "./assets/images/group_meeting.png",
  GreenDoubleTick: "./assets/images/green_double_tick.png",
  GrayDoubleTick: "./assets/images/gray_double_tick.png",
  ToursAndTournament: "./assets/images/tours_and_tournament.png",
  SuitcaseImage: "./assets/images/suitcase_image.png",
  AirPlaneBG: "./assets/images/airplane_bg.png",
  CricketBanner: "./assets/images/cricket_banner.png",
  FootBallBanner: "./assets/images/football_banner.png",
  CasinoBanner: "./assets/images/casino_banner.png",
  EntBanner: "./assets/images/man_suitcase.png",
  DiscountIcon: "./assets/images/discount_icon.png",
  TourGiftBox: "./assets/images/tour_gift_box.png",
  coins: "./assets/images/coin.png",
  moneyImage: "./assents/money.png",

  Poster1: "/assets/images/poster_1.png",
  Poster2: "/assets/images/poster_2.png",
  Poster3: "/assets/images/poster_3.png",
  Poster4: "/assets/images/poster_4.png",
  Poster5: "/assets/images/poster_5.png",

  // callWaiting: "./assets/images/calling.png",
};

export const ImageBaseUrl =
  "https://we2-call-images.s3.us-east-2.amazonaws.com";

export const ImageBaseUrl2 =
  "https://we2-call-images-singapore.s3.ap-southeast-1.amazonaws.com";
  
  export const UpdatedBaseUrl =
  "https://we2-call-images.s3.us-east-2.amazonaws.com";