export const app_base_url =
   "https://m4dgup943c.execute-api.ap-southeast-1.amazonaws.com/prod";

   export const Signed_url =
  "https://gddigb51ed.execute-api.us-east-2.amazonaws.com/prod";

// added chat base url
export const chat_base_url =
  "wss://66zc1ns3z9.execute-api.ap-southeast-1.amazonaws.com/production";

  export const approval_base_url = 'wss://6y4kyg068a.execute-api.ap-southeast-1.amazonaws.com/production/';


  export const Zeego_server_url = "wss://webliveroom1415017287-api-bak.coolzcloud.com/ws";
  export const Zeego_AppId =  1415017287;

// export const app_base_url =
//   "https://gddigb51ed.execute-api.us-east-2.amazonaws.com/prod";

// export const chat_base_url =
//   "wss://on2l783adb.execute-api.us-east-2.amazonaws.com/production"; // ohio region
