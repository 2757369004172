import { IonImg, IonModal, useIonToast } from "@ionic/react";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { useState } from "react";
import { Images } from "../../Images";
import { LOGIN_API } from "../../api/endpoints";
import { call } from "../../api/axios";
import { useDispatch, useSelector } from "react-redux";
import { openLoginModal } from "../../redux/action";
import { useHistory } from "react-router";

function LoginPopup(props) {
  const history = useHistory();
  const [isVisible, setIsVisible] = useState(false);
  const [user_name, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);
  const { setOpenModel } = props;
  const loginModalStatus = useSelector(
    (State) => State?.userReducer?.loginModalStatus
  );
  const dispatch = useDispatch();
  const handleCloseStatement = () => {
    dispatch(openLoginModal(false));
  };
  const handleSignUp = () => {
    setOpenModel(true);
  };
  const handleEyeicon = () => {
    setIsVisible((prev) => !prev);
  };
  const onInputChange = (e) => {
    if (e.target.name === "user_name") {
      setUserName(e.target.value);
    } else {
      setPassword(e.target.value);
    }
  };

  const onLoginClick = async () => {
    if (!user_name) {
      return presentToast("Username is Required");
    }
    if (!password) {
      return presentToast("Password is Required");
    }
    setIsProcessing(true);
    await call(LOGIN_API, { user_name, password })
      .then((res) => {
        setIsProcessing(false);
        if (res.data.status === 200) {
          localStorage.setItem("register_id", res?.data?.data?.register_id);
          localStorage.setItem("creator_id", res?.data?.data?.creator_id);
          localStorage.setItem("account_role", res?.data?.data?.account_role);
          localStorage.setItem("user_name", res?.data?.data?.user_name);
          localStorage.setItem("refferal_code", res?.data?.data?.refferal_code);
          localStorage.setItem("creator_name", res?.data?.data?.creator_name);
          window.location.reload();
          history.push("/");
        } else {
          setIsProcessing(false);
          return presentToast(res.data.message);
        }
      })
      .catch((err) => {
        setIsProcessing(false);
        return presentToast(err?.message ? err?.message : "Network error");
      });
  };

  const [present] = useIonToast();

  const presentToast = (message) => {
    present({
      message: message,
      duration: 1500,
      position: "middle",
      color: "danger",
    });
  };
  return (
    <IonModal
      isOpen={true}
      animated
      backdropDismiss={false}
      className="statememt-popup-main"
    >
      <div className="login-bg p-10">
        <div className="flex-jc-sb mb-10">
          <span className="reports-heading">Login</span>
          <IonImg className="popup-img" src={Images.We2callDark} />
        </div>
        <div className="series-input flex-jc-c mb-10">
          <input
            className="flex-jc-c w-90 match-input"
            placeholder="Enter User Name"
            name="user_name"
            value={user_name}
            onChange={onInputChange}
            type="text"
          ></input>
        </div>
        <div className="series-input flex-jc-c mb-10">
          <input
            className="flex-jc-c w-80 match-input"
            placeholder="Enter Password"
            name="password"
            value={password}
            type={isVisible ? "text" : "password"}
            onChange={onInputChange}
          ></input>
          {isVisible ? (
            <AiFillEye
              className="dropdown-icon"
              onClick={() => handleEyeicon()}
            />
          ) : (
            <AiFillEyeInvisible
              className="dropdown-icon"
              onClick={() => handleEyeicon()}
            />
          )}
        </div>
        {/* <div className="d-flex-jc-end mb-10">Forgot Your Password ?</div> */}
        <button
          type="submit"
          className="submit-button w-100 mb-10"
          onClick={onLoginClick}
          disabled={isProcessing}
        >
          {isProcessing ? "Processing..." : "Login"}
        </button>
      </div>

      {/* <div className="flex-jc-c mb-10">
        Don't Have Account ?
        <span className="clr-grn" onClick={() => handleSignUp()}>
          SignUp
        </span>
      </div> */}
    </IonModal>
  );
}

export default LoginPopup;
