import { useEffect, useState } from "react";
import { BsSend } from "react-icons/bs";
import { BiCamera } from "react-icons/bi";
import { ImAttachment } from "react-icons/im";
import { AiOutlineAudio } from "react-icons/ai";
import "./styles.css";
import ScrollableFeed from "react-scrollable-feed";
import { open, send } from "../../utils/WebSocket";
import { GET_USER_MESSAGES, GET_USER_INFO, GENERATE_SIGNED_URL, GET_ADMIN_DETAILS } from "../../api/endpoints";

import { call, Signed } from "../../api/axios";
import moment from "moment";
import NoChats from "./NoChats";
import { IonHeader } from "@ionic/react";
import { useHistory, useParams } from "react-router";
import { FaChevronLeft } from "react-icons/fa6";

function Chats() {
  const history = useHistory();
  const ImageBaseUrl = "https://we2-call-images-singapore.s3.ap-southeast-1.amazonaws.com";
  const { id, name } = useParams();
  const register_id = localStorage?.getItem("register_id");
  const creator_id = localStorage?.getItem("creator_id");
  const creator_name = localStorage?.getItem("creator_name");

  const [clientsData, setClientsData] = useState([]);
  const [chatMessages, setChatMessages] = useState([]);
  const [userInput, setUserInput] = useState("");
  const [file, setFile] = useState([]);
  const [image, setImage] = useState("");
  const [signedUrl, setSignedUrl] = useState();
  const [adminData, setAdminData] = useState();

  const [trxId, setImageTrxId] = useState("");

  useEffect(() => {
    getAllUsersData();
    getAllAdminsData();
  }, []);

  const handleChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file);
      generateSignedUrl();
    }
  };

  const generateSignedUrl = async () => {
    const trxId = new Date().getTime();
    await Signed(GENERATE_SIGNED_URL, {
      trxId,
      event_type: "user_profile_image",
      folder_name: "user_messages",
    })
      .then(async (res) => {
        debugger;
        let url = res?.data?.data?.result?.signed_url;
        setSignedUrl(url);
        // setImageTrxId(trxId);
        await imageUpploadToBucket();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const imageUpploadToBucket = async () => {
    signedUrl &&
      image &&
      (await fetch(signedUrl, {
        method: "PUT",
        body: image,
        headers: {
          "Content-Type": "image/jpeg",
          "cache-control": "public, max-age=0",
        },
      })
        .then((res) => {
          debugger;
          console.log("imageUpploadToBucket called:", res);
          return true;
        })
        .catch((err) => console.log(err)));
  };

  // const handleChange = (e) => {
  //   const file = e.target.files[0];
  //   if (file) {
  //     const reader = new FileReader();
  //     reader.onloadend = () => {
  //       setSelectedImage(reader.result);
  //       generateSignedUrl(register_id);
  //     };
  //     reader.readAsDataURL(file);
  //   }
  // };

  const getAllUsersData = async () => {
    await call(GET_USER_INFO, {
      register_id,
    })
      .then((res) => {
        setClientsData(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllAdminsData = async () => {
    await call(GET_ADMIN_DETAILS, {
      register_id: creator_id,
    })
      .then((res) => {
        setAdminData(res?.data?.data[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  };
 

  // const inputHandler = async () => {
  //   setUserInput("");
  //   await send(userInput);
  // };
  const inputHandler = async () => {
    const to_user_id = localStorage?.getItem("creator_id");
    if (image) {
      debugger;
      // await imageUpploadToBucket();
      // const imageUrl = `${ImageBaseUrl}/${"chat-images"}/${register_id}/${selectedImage.name}`;
      // const imageUrl = `${ImageBaseUrl}/${"user_messages"}/${trxId}.png`;
      const index = signedUrl.indexOf(".png");
      if (index !== -1) {
        var urlWithoutParams = signedUrl.slice(0, index + 4);
      }
      const imageUrl = `${urlWithoutParams}`;
      await send(imageUrl, "image", to_user_id);
      setUserInput("");
      setImage(null);
    } else if (userInput.trim() !== "") {
      await send(userInput, "text", to_user_id);
      setUserInput("");
    }
  };

  const addMessage = (message, msg_c = 0) => {
    let temp = { ...message, ts: new Date().getTime(), msg_c };
    setChatMessages((prev) => {
      if (!Array.isArray(prev)) {
        return [temp];
      }
      return [...prev, temp];
    });
  };

  const hanldeKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftyKey) {
      event.preventDefault();
      inputHandler();
    }
  };

  const getAllUserMessages = async () => {
    await call(GET_USER_MESSAGES, {
      from_user_id: localStorage?.getItem("creator_id"),
      to_user_id: localStorage?.getItem("register_id"),
    })
      .then(async (res) => {
        debugger;
        setChatMessages(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onMessageRecieve = (event) => {
    if (!event.data) {
      return;
    }
    const msg = JSON.parse(event.data);
    addMessage(msg);
  };

  useEffect(() => {
    getAllUserMessages();
  }, []);

  useEffect(() => {
    open({ onmessage: onMessageRecieve });
  }, [chatMessages]);

  return (
    <div>
      <div>
        <IonHeader className="user-outer-div">
          <div className="flex-jc-c">
            <div className="flex-center custom-ionicon-container custom-back-icon" onClick={() => history.push("/")}>
              <FaChevronLeft />
            </div>
            <img className="custom-ionicon-container custom-meeting-image" src={adminData?.profile_image} />
            <div className="meetings-heading ml-5">
              <div>{adminData?.user_name}</div>
            </div>
          </div>
        </IonHeader>
        <div className="msg-container" id="chat">
          <ScrollableFeed>
            {chatMessages?.length > 0 ? (
              chatMessages.map((msg, index) => {
                return (
                  <div key={index}>
                    <div className={`mt-5 ${msg?.from_user_id !== creator_id ? "chat-box-incoming" : "chat-box-outgoing"}`}>
                      <div key={index} className={`mt-5 message ${msg?.to_user_id !== id ? "incoming-message" : "outgoing-message"}`}>
                        {typeof msg.message === "string" && msg.message.includes("http") ? <img src={msg?.message} alt="chat-image" /> : typeof msg.message != "object" && !Array.isArray(msg.message) ? <div>{msg.message}</div> : <></>}
                      </div>
                      {/* <div className={`mt-5 message ${msg?.from_user_id !== creator_id ? "incoming-message" : "outgoing-message"}`}>{msg?.message}</div> */}
                      <div className="date-position date-text mt-5">{moment(msg?.ts).format("hh:mm a")}</div>
                    </div>
                  </div>
                );
              })
            ) : (
              <NoChats />
            )}
          </ScrollableFeed>
        </div>
      </div>
      <div
        className="flex message-send-box "
        // onClick={(e) => {
        //   e.preventDefault();
        // }}
      >
        <div className="message-send-container ml-15">
          <textarea name="postMessage" rows={1} value={userInput} className="send-text-area" autoFocus placeholder="Write here ......" onChange={(e) => setUserInput(e.target.value)} onKeyDown={(e) => userInput && hanldeKeyDown(e)} />
          <button className="flex send-icon" onClick={() => inputHandler()}>
            <BsSend />
          </button>
        </div>

        <div className="chat-icon-button-carrier ">
          {/* <div
            className="send-icon message-icons"
            onClick={() => handleChange()}
          >
            <BiCamera className="chat-icons" />
            <input
              type="file"
              id="upload-button"
              style={{ display: "none" }}
              onChange={handleChange}
            />
          </div> */}

          <div className="send-icon message-icons">
            <label htmlFor="upload-button">
              <BiCamera className="chat-icons" />
            </label>
            <input type="file" id="upload-button" style={{ display: "none" }} onChange={handleChange} />
          </div>
          {/* <div className="send-icon message-icons">
            <BiCamera className="chat-icons" />
            <label htmlFor="upload-button">
              <input type="file" id="upload-button" style={{ display: "none" }} onChange={handleChange} />
            </label>
          </div> */}
          <div className="send-icon message-icons">
            <ImAttachment className="chat-icons" />
          </div>
          <div className="send-icon message-icons">
            <AiOutlineAudio className="chat-icons" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Chats;
