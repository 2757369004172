import Sockette from "sockette";
import { chat_base_url } from "../config/config_local";

let ws;
let events;
let isConnecttionOpen = false;
export let openConnections = {};

export const open = (events = {}) => {
  events = events;
  
  return new Promise((resolve, reject) => {
    ws = new Sockette(
      `${chat_base_url}?userid=${localStorage?.getItem("register_id")}`,
      {
        timeout: 5e3,
        maxAttempts: 1,
        onopen: (e) => {
          console.log("onopen : ", e);
          isConnecttionOpen = true;
          resolve();
        },
        onmessage: (e) => {
          console.log("Message Received:", e);
          try {
            const message = JSON.parse(e.data);
            if (events.onmessage) {
              events.onmessage(message);
            }
          } catch (error) {
            console.error("Error parsing message:", error);
          }
        },

        onreconnect: (e) => console.log("Reconnecting...", e),
        onmaximum: (e) => {
          console.log("Stop Attempting!", e);
          reject(new Error("Maximum attempts reached"));
        },
        onclose: (e) => {
          console.log("Closed!", e);
          isConnecttionOpen = false;
        },
        onerror: (e) => {
          console.log("Error:", e);
          reject(new Error("WebSocket error"));
        },
        ...events,
      }
    );
  });
};

export const send = async (data, messageType, toUser) => {
  if (!isConnecttionOpen) {
    await open(events);
  }
  // ws.send(
  //   JSON.stringify({
  //     action: "onmessage",
  //     message,
  //     imageUrl,
  //     from_user: localStorage?.getItem("register_id"),
  //     to_user: toUser,
  //   })
  // );
  
  let payload;
  debugger
  if (messageType === "text") {
    payload = {
      action: "onmessage",
      message: data,
      from_user: localStorage?.getItem("register_id"),
      to_user: toUser,
    };
  } else if (messageType === "image") {
    payload = {
      action: "onmessage",
      message: data,
      from_user: localStorage?.getItem("register_id"),
      to_user: toUser,
    };
  }

  ws.send(JSON.stringify(payload));
};


export const openrequestJoin = (events = {}) => {
  events = events;
  const userId = localStorage?.getItem("register_id");
  return new Promise((resolve, reject) => {
    ws = new Sockette(
      `${chat_base_url}?userid=${localStorage?.getItem("register_id")}`,
      {
        timeout: 5e3,
        maxAttempts: 1,
        onopen: (e) => {
          console.log("onopen : ", e);
          openConnections[userId] = ws;
          isConnecttionOpen = true;
          resolve();
        },
        onmessage: (e) => {
          console.log("Join request received:", e);
          try {
            const message = JSON.parse(e.data);
            if (events.onmessage) {
              events.onmessage(message);
            }
          } catch (error) {
            console.error("Error parsing message:", error);
          }
        },

        onreconnect: (e) => console.log("Reconnecting...", e),
        onmaximum: (e) => {
          console.log("Stop Attempting!", e);
          reject(new Error("Maximum attempts reached"));
        },
        onclose: (e) => {
          console.log("Closed!", e);
          isConnecttionOpen = false;
        },
        onerror: (e) => {
          console.log("Error:", e);
          reject(new Error("WebSocket error"));
        },
        ...events,
      }
    );
  });
};

export const sendRequestJoin = async (data, toUser) => {
  if (!isConnecttionOpen) {
    await open(events);
  }
    const payload = {
      action: "onmessage",
      message: data,
      from_user: localStorage?.getItem("register_id"),
      to_user: toUser,
    };
  ws.send(JSON.stringify(payload));
};

export const close = () => {
  ws.close();
};
