// import { createStore } from 'redux';
import rootReducer from './reducer/rootReducer'; // Assuming you have a root reducer that combines all reducers

// const store = createStore(rootReducer);

// export default store;
import { createStore, applyMiddleware } from 'redux'
import thunkMiddleware from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
// import rootReducer from './reducer'

const composedEnhancer = composeWithDevTools(applyMiddleware(thunkMiddleware))

// The store now has the ability to accept thunk functions in `dispatch`
const store = createStore(rootReducer, composedEnhancer)
export default store