import { IonModal, useIonToast } from "@ionic/react";
import { IoCloseSharp } from "react-icons/io5";
import { MdOutlineContentCopy } from "react-icons/md";
import { FaShareFromSquare } from "react-icons/fa6";
import { useEffect, useState } from "react";
import { call } from "../../api/axios";
import { GET_USER_INFO } from "../../api/endpoints";
import { Images } from "../../Images";
import ShareButtons from "./ShareButtons";

function ReferID(props) {
  const [present] = useIonToast();
  const presentToast = (message) => {
    present({
      message: message,
      duration: 1500,
      position: "middle",
      color: "danger",
    });
  };
  const { referModal, handleReferModal } = props;
  const [showShareSection, setShowShareSection] = useState(false);
  const [inputData, setInputData] = useState({});
  const id = localStorage.getItem("register_id");
  const themeChange = localStorage.getItem("theme");
  const admin_id = localStorage?.getItem("creator_id");
  const WEBSITE_URL = `www.we2call.com`;
  const referID = localStorage?.getItem("refferal_code");

  const referralData = WEBSITE_URL;
  const handleShowShareButton = (e) => {
    e.preventDefault();
    setShowShareSection(true);
  };

  const handleCopyText = (event) => {
    event.preventDefault();
    navigator.clipboard.writeText(referralData).then(() => {
      presentToast("Copied Successfully!");
    });
  };

  const getUserProfile = async () => {
    await call(GET_USER_INFO, {
      register_id: id,
    }).then((resp) => {
      if (resp.data.status === 200) {
        localStorage?.setItem("profileImage", resp?.data?.data?.profile_image);
        setInputData({
          refferal_code: resp.data.data.refferal_code,
          admin_refferal_code: resp.data.data.admin_refferal_code,
          creator_id: resp?.data?.data?.creator_id,
        });
      }
    });
  };

  useEffect(() => {
    if (id) {
      getUserProfile();
    }
  }, []);
  return (
    <IonModal id="referral-modal" isOpen={referModal}>
      <div className="login-bg p-10">
        <div className="w-100 d-flex-jc-end">
          <IoCloseSharp className="close-icon" onClick={handleReferModal} />
        </div>
        <div className="flex-jc-c">
          <img
            className="refer-w2c-img"
            src={
              themeChange === "dark-mode"
                ? Images?.We2callDark
                : Images?.We2CallLight
            }
          />
        </div>
        <form>
          <div className="mt-10">
            <label className="input-label font-14">Refer/Share ID</label>
            <input
              placeholder="Enter Refer/Share ID"
              type="text"
              disabled
              value={inputData.refferal_code}
              className="email-input pl-10 mt-5 w-100 font-14"
            />
          </div>
          <div className="mt-10">
            <label className="input-label font-14">Admin Head ID</label>
            <input
              placeholder="Admin Head ID"
              type="text"
              disabled
              value={inputData.creator_id}
              className="email-input pl-10 mt-5 w-100 font-14"
            />
          </div>
          <div className="mt-10">
            <label className="input-label font-14">Website URL</label>
            <input
              placeholder="Website URL"
              type="text"
              disabled
              value="www.we2call.com"
              className="email-input pl-10 mt-5 w-100 font-14"
            />
          </div>
          <div className="flex-jc-sb mt-15">
            <button
              className="w-45 h-38px br-5 ml-5 mb-5 copy-button"
              onClick={(e) => handleCopyText(e)}
            >
              <MdOutlineContentCopy className="copy-icon mr-5 font-14" />
              Copy
            </button>
            <button
              className="w-45 h-38px br-5 ml-5 mb-5 share-button"
              onClick={(e) => handleShowShareButton(e)}
            >
              <FaShareFromSquare className="share-icon mr-5 font-14" />
              Share
            </button>
          </div>
          {showShareSection && <ShareButtons data={referralData} />}
        </form>
      </div>
    </IonModal>
  );
}

export default ReferID;
