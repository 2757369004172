import ViewOffers from "./ViewOffers";
import ToursHeader from "./ToursHeader";

function Offers() {
  return (
    <div>
      <ToursHeader heading={"Offers"} />
      <ViewOffers />
    </div>
  );
}

export default Offers;
