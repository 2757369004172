import { IonImg } from "@ionic/react";
import { MdMicNone, MdMicOff, MdVideocam, MdVideocamOff } from "react-icons/md";
import callingProfile from "./asssets/Calling-Profile.png";
import weLogo from "./asssets/We-Logo.png";
import { useHistory } from "react-router";
import { useState } from "react";

function CallingMain() {
  const history = useHistory();
  const [isMicOn, setIsMicOn] = useState(true);
  const [isVideoOn, setIsVideoOn] = useState(true);

  const toggleMic = () => {
    setIsMicOn((prevState) => !prevState);
  };

  const toggleVideo = () => {
    setIsVideoOn((prevState) => !prevState);
  };
  return (
    <div className="h-100vh d-flex justify-content-start align-items-center flex-column">
      <div className="d-flex justify-content-center w-100 h-30vh align-items-center">
        <IonImg src={weLogo} className="profile-calling"></IonImg>
      </div>
      <div className="call-blue-bg h-50vh w-80 br-20">
        <div className="d-flex justify-content-center w-100">
          <IonImg src={callingProfile} className="profile-calling"></IonImg>
        </div>
        <div className="w-100 d-flex flex-column justify-content-center align-items-center">
          <div className="clr-white fw-600 font-18">Srinivas</div>
          <div className="clr-white  font-16 mt-5">
            Inviting for audio call...
          </div>
          <div className="clr-white font-16 mt-20">Mic is On..</div>
          <div className="w-50 flex-space-between">
            <div
              className={`w-40 d-flex justify-content-center align-items-center h-8vh br-10 mt-40 join-call-btn  ${
                isMicOn ? "green-bg" : "red-bg"
              }`}
              onClick={toggleMic}
            >
              {isMicOn ? (
                <MdMicNone className="font-40 clr-white" />
              ) : (
                <MdMicOff className="font-40 clr-white" />
              )}
            </div>
            <div
              className={`w-40 d-flex justify-content-center align-items-center h-8vh br-10 mt-40 join-call-btn  ${
                isVideoOn ? "green-bg" : "red-bg"
              }`}
              onClick={toggleVideo}
            >
              {isVideoOn ? (
                <MdVideocam className="font-40 clr-white" />
              ) : (
                <MdVideocamOff className="font-40 clr-white" />
              )}
            </div>
          </div>
          <div className="w-100 d-flex flex-row justify-content-between p-20 mt-40">
            <input
              className="w-65 input-bg-call p-14 br-10 border-none-call clr-white font-16"
              placeholder="Enter Your Name"
            />
            <div
              className="w-30 green-bg  d-flex justify-content-center align-items-center clr-white font-16 br-10"
              onClick={() => history.push("waiting")}
            >
              Join
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CallingMain;
