import {
  GET_USER_DATA,
  LOGIN_MODAL,
  SIGN_UP_USER,
  SET_MEETING_IS_STARTED,
  SET_MAXMIZE,
  SET_MINIMIZE,
  LIVE_MEETING,
} from "../constants";

const initialState = {};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_USER_DATA:
      return { ...state, ...payload };
    case SIGN_UP_USER:
      return { ...state, signUpData: payload };
    case LOGIN_MODAL:
      return { ...state, loginModalStatus: payload };
    case SET_MAXMIZE:
      return { ...state, isMaxmize: payload };
    case SET_MINIMIZE:
      return { ...state, isMinimize: payload };
    case SET_MEETING_IS_STARTED:
      return { ...state, isMeetingStarted: payload };
    case LIVE_MEETING:
      return { ...state, meeting: payload };
    default:
      return state;
  }
};
