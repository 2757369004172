import "./styles.css";
import { useHistory } from "react-router";
import { IonHeader, IonImg } from "@ionic/react";
import { Images } from "../../Images";
import ReferID from "../referrals/ReferID";
import { useState } from "react";
import { FaChevronLeft } from "react-icons/fa6";
import { FiShare2 } from "react-icons/fi";

function EditProfileHeader() {
  const history = useHistory();
  const [referModal, setReferModal] = useState(false);
  const handleReferModal = () => {
    setReferModal((prev) => !prev);
  };
  return (
    <IonHeader className="user-outer-div">
      <div className="flex-jc-c">
        <div
          className="custom-ionicon-container custom-back-icon flex-center"
          onClick={() => history.push("/")}
        >
          <FaChevronLeft className="font-25" />
        </div>
        <div className="meetings-heading ml-5">Edit Profile</div>
      </div>
      {/* <div className="header-right">
        <div
          className="custom-ionicon-container custom-back-icon"
          onClick={() => handleReferModal()}
        >
          <FiShare2 />
        </div>
      </div> */}
      <ReferID handleReferModal={handleReferModal} referModal={referModal} />
    </IonHeader>
  );
}

export default EditProfileHeader;
