import { SlPlane } from "react-icons/sl";
import { BiCalendar } from "react-icons/bi";
import { HiThumbUp } from "react-icons/hi";
import "./styles.css";
import ToursHeader from "./ToursHeader";
import "./common.css";
import { Images } from "../Images";

function ToursAndTournaments() {
  const handleClick = () => {
    window.open("https://www.google.com", "_blank", "noopener,noreferrer");
  };
  return (
    <div>
      <ToursHeader heading={"Tours & Tournaments"} />
      <div className="we-2-call-main-section">
        <div className="meetings-container w-92">
          <div className="w-100 flex-space-around take-tour-div mt-10">
            <div className="w-50 ml-15">
              <div className="font-18 mt-5 white-text">
                Take a part in <br /> our tour <SlPlane />
              </div>
              <div className="font-12 mt-5 white-text">
                Play and get a chance to join with tour
              </div>
              <div className="flex-center intrested-container mt-5">
                <div
                  onClick={() => handleClick()}
                  className="flex-center intrested-div w-50 font-12 white-text"
                >
                  I’m intrested
                </div>
                <div className="flex-center flex-column w-50">
                  <div className="flex-center font-12 white-text">
                    <HiThumbUp className="thumbsup-icon mr-5 font-14 white-text" />
                    1000K
                  </div>
                  <div className="font-10 white-text">are intrested</div>
                </div>
              </div>
              <div className="font-12 mt-5 white-text">
                Tour Start Date:<b> 2nd Aug, 2023</b>
              </div>
            </div>
            <div className="w-45 suitcase-img-div">
              <img
                className="suitcase-image"
                src={Images.SuitcaseImage}
                alt="Suitcase_Img"
              />
            </div>
          </div>
          <div className="w-100 flex-space-between sports-tour-div mt-10">
            <div className="w-48 cricket-tour-div flex-space-between">
              <div className="w-50">
                <img
                  className="football-image"
                  src={Images.CricketBanner}
                  alt="Cricket_Banner"
                />
              </div>
              <div className="w-60 cricket-text-div pr-5">
                <div className="font-16 white-text">Cricket Cup Tour</div>
                <div className="font-10 mt-5 white-text">
                  Click on interest buttons to get a changce to a part of our
                  tour
                </div>
                <div
                  onClick={() => handleClick()}
                  className="flex-center cricket-intrested-div w-100 mt-5"
                >
                  I’m intrested
                </div>
                <div className="font-10 mt-5 white-text">Tour Start Date</div>
                <div className="font-10 mt-5">
                  <b className="flex">
                    <BiCalendar className="tour-calender-icon white-text" />
                    Coming Soon!
                  </b>
                </div>
              </div>
            </div>
            <div className="w-48 football-tour-div flex-space-between">
              <div className="w-50">
                <img
                  className="football-image"
                  src={Images.FootBallBanner}
                  alt="Cricket_Banner"
                />
              </div>
              <div className="w-60 football-text-div pr-5">
                <div className="font-16 white-text">
                  Football <br /> Tour
                </div>
                <div className="font-10 mt-5 white-text">
                  Click on interest buttons to get a changce to a part of our
                  tour
                </div>
                <div
                  onClick={() => handleClick()}
                  className="flex-center cricket-intrested-div w-100 mt-5"
                >
                  I’m intrested
                </div>
                <div className="font-10 mt-5 white-text">Tour Start Date</div>
                <div className="font-10 mt-5">
                  <b className="flex">
                    <BiCalendar className="tour-calender-icon white-text" />
                    Coming Soon!
                  </b>
                </div>
              </div>
            </div>
          </div>
          <div className="w-100 flex-space-around casino-tour-div mt-10">
            <div className="w-50 ml-15">
              <div className="clr-black font-18 mt-5">
                Casino <br /> Tournament
              </div>
              <div className="clr-black font-12 mt-5">
                Play and get a chance to join with tour
              </div>
              <div className="flex-center casino-container mt-5">
                <div
                  onClick={() => handleClick()}
                  className="flex-center casino-div w-50 font-12"
                >
                  I’m intrested
                </div>
                <div className="flex-center flex-column w-50">
                  <div className="clr-black flex-center font-12">
                    <HiThumbUp className="thumbsup-icon mr-5 font-14" />
                    179.1K
                  </div>
                  <div className="clr-black font-10">are intrested</div>
                </div>
              </div>
              <div className="clr-black font-12 mt-5">
                Tour Start Date:<b> 2nd Aug, 2023</b>
              </div>
            </div>
            <div className="w-45 casino-img-div">
              <img
                className="suitcase-image"
                src={Images.CasinoBanner}
                alt="Suitcase_Img"
              />
            </div>
          </div>

          <div className="w-100 flex-space-around ent-tour-div mt-10">
            <div className="w-45 ent-img-div">
              <img
                className="suitcase-image"
                src={Images.EntBanner}
                alt="Suitcase_Img"
              />
            </div>
            <div className="w-50 ml-15">
              <div className="font-18 mt-5 white-text">
                Entertainment <br /> Tour
              </div>
              <div className="font-12 mt-5 white-text">
                Play and get a chance to join with tour
              </div>
              <div className="flex-center ent-container mt-5">
                <div
                  onClick={() => handleClick()}
                  className="flex-center ent-div w-50 font-12 white-text"
                >
                  I’m intrested
                </div>
                <div className="flex-center flex-column w-50">
                  <div className="flex-center font-12 white-text">
                    <HiThumbUp className="mr-5 font-14 white-text" />
                    179.1K
                  </div>
                  <div className="font-10 white-text">are intrested</div>
                </div>
              </div>
              <div className="font-12 mt-5 white-text">
                Tour Start Date:<b> 2nd Aug, 2023</b>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ToursAndTournaments;
