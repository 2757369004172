function CompletedMeetings(props) {
  const { allCompletedMeetings = [] } = props;

  return (
    <div className="meetings-container w-92">
      <div className="meetings-heading mt-10">Completed Meetings</div>
      <div>
        <div className="flex-jc-sb mt-15">
          <div className="meetings-button custom-box-shadow flex-jc-c w-30">
            Date & Time
          </div>
          <div className="meetings-button custom-box-shadow flex-jc-c w-30">
            Meeting Title
          </div>
          <div className="meetings-button custom-box-shadow flex-jc-c w-30">
            Duration
          </div>
        </div>
        {allCompletedMeetings &&
          allCompletedMeetings?.map((meeting, index) => {
            const timestamp = meeting.date;
            const dateObject = new Date(timestamp);
            const day = dateObject.getDate();
            const month = dateObject.getMonth() + 1;
            const year = dateObject.getFullYear();
            return (
              <div
                className="flex-jc-sa custom-box-shadow meetings-details-div mt-10"
                key={index}
              >
                <div className="flex-jc-se flex-column custom-box-shadow meetings-date-time-div w-22">
                  <div className="meetings-date fw-600">{`${day}/${month}/${year}`}</div>
                  <div className="meetings-time">{meeting?.time}</div>
                </div>
                <div className="meetings-time w-30 padding-tb">
                  {meeting?.event_name}
                  <br />
                  {meeting?.match_name}
                </div>
                <div className="meetings-time clr-red">Completed</div>
              </div>
            );
          })}
      </div>
    </div>
  );
}

export default CompletedMeetings;
