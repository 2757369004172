import { ZegoExpressEngine } from "zego-express-engine-webrtc";
import moment from "moment";
import React, { useEffect, useState, useRef } from "react";
import { useHistory, useLocation } from "react-router";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Images } from "../Images";
// import toast from 'react-hot-toast';

import { call } from "../config/axios";
import { GET_ALL_CLIENTS, GET_USER_INFO, GET_LIVE_MEETING, SIGNAL_RECORDING, GET_ADMIN_PACKAGES } from "../config/endpoints";
import "./styles.css";
// import { Images } from "../../images";
// import { setLoading } from "../../redux/actions";
// import {
//   setMeetingStartStatus,
//   setUserJoinClick,
// } from "../../redux/actions/dataActions";
import { FaMicrophone, FaVideo, FaVideoSlash, FaMicrophoneSlash } from "react-icons/fa";

const Join = ({ id, UserJoinClick }) => {
  const location = useLocation();
  const meetingId = location.pathname.split("/").slice(-1)?.[0];
  console.log("meetingId", meetingId);
  // const { meetingId, creatorId } = useParams();
  const history = useHistory();
  const inputref = useRef(null);
  const [micOn, setMicOn] = useState(true);
  const [camOn, setCamOn] = useState(true);
  const [waiting, setWaiting] = useState(false);
  const [adminSubscription, setAdminSubscription] = useState([]);
  const [profileImage, setProfileImage] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [liveMeeting, setLiveMeeting] = useState({});
  const isAdminCreated = localStorage.getItem("isAdminMeeting") === "true";

  const first_name = localStorage.getItem("first_name");
  const last_name = localStorage.getItem("last_name");
  const register_id = localStorage.getItem("register_id");

  useEffect(() => {
    getLiveMeetingData();
    // getUserProfile();
    console.log("register_id:", register_id);
  }, []);

  const getLiveMeetingData = async () => {
    const creator_id = isAdminCreated ? localStorage.getItem("register_id") : localStorage.getItem("creator_id");
    return await call(GET_LIVE_MEETING, {
      creator_id: creator_id,
      meeting_id: meetingId,
    })
      .then((res) => {
        console.log("res", res);
        setLiveMeeting(res.data?.data?.Items?.[0]);
      })
      .catch((err) => {
        throw err;
      });
  };

  //     const getAdminSubscription = async () => {
  //       return await call(GET_ADMIN_PACKAGES, {
  //         register_id: register_id,
  //       })
  //         .then((res) => {
  //             console.log("getAdminSubscription", res);
  //             const subscriptions = res?.data?.data?.flatMap(item => item?.subscriptions?.flatMap(sub => sub.Items));
  //             setAdminSubscription(subscriptions);
  //             console.log("subscriptions", subscriptions);
  //         })
  //         .catch((err) => {
  //           throw err;
  //         });

  //   };

  const handleOpenJoinPopup = async () => {
    console.log("data", liveMeeting);
    console.log("adminSubscription", adminSubscription);
    let obj = {
      meeting_id: meetingId,
      user_name: inputref.current.value,
      type: "join_meeting",
      user_id: localStorage.getItem("register_id"),
      creator_id: liveMeeting?.p_id,
      meeting_type: liveMeeting?.meeting_type,
      camera: camOn,
      micro: micOn,
      videoType: liveMeeting.video_call_type,
    };
    localStorage.setItem("on_going_meeting", JSON.stringify(obj));
    // history.push(`/meeting/${meetingId}`);
    history.push(`/group-call/${meetingId}`);
  };

//   const getUserProfile = async () => {
//     await call(GET_USER_INFO, { register_id })
//       .then((res) => {
//         console.log("getUserProfile", res);
//         if (res?.data?.status === 200) {
//           setProfileImage(res?.data?.data.profile_image);
//         }
//       })
//       .catch((err) => console.log(err));
//   };

  return (
    <>
      <div className="join-container">
        <div className="img-join">
          <img src={Images.We2callDark} alt="join" />
        </div>
        <div className="join-box">
          {/* <img src={profileImage || Images.DhoniImage} alt="" /> */}
          <img src={localStorage?.getItem("profileImage")} alt="profile_image" />
          <div className="name-box">
            <h3>
              {first_name} {last_name}
            </h3>
            <p>inviting for video call...</p>
          </div>
          <div className="cam-micro">
            <p>
              {liveMeeting?.video_call_type === "audio-video" ? <>{camOn ? <></> : <span>Camera is Off...</span>}</> : <></>}

              {micOn ? <></> : <span>Microphone is Off...</span>}
            </p>
          </div>
          <div className="mic-cam-icon">
            {liveMeeting?.video_call_type === "audio-video" ? (
              <>
                <div className={`box-mic ${camOn ? "box-green" : "box-red"}`} onClick={() => setCamOn(!camOn)}>
                  {camOn ? <FaVideo /> : <FaVideoSlash />}
                </div>
              </>
            ) : (
              <></>
            )}

            <div className={`box-mic ${micOn ? "box-green" : "box-red"}`} onClick={() => setMicOn(!micOn)}>
              {micOn ? <FaMicrophone /> : <FaMicrophoneSlash />}
            </div>
          </div>
          <div className="input-but">
            <input type="text" ref={inputref} onChange={(e) => setInputValue(e.target.value)} placeholder="Enter your name" />
            <button className="btn btn-join" disabled={inputValue === ""} onClick={() => handleOpenJoinPopup()}>
              Join
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Join;
