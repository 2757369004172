import { useEffect, useState } from "react";
import "./styles.css";
import { useHistory } from "react-router";

function Footer() {
  const id = localStorage.getItem("register_id");

  const FooterMenu = [
    {
      tab: "chat",
      img: "./assets/images/chat_icon.png",
    },
    {
      tab: "home",
      img: "./assets/images/Home.png",
    },
    {
      tab: "Groupcall",
      img: "./assets/images/group_meeting.png",
      heading: "Meetings",
    },
  ];

  const footerImages = [
    "/assets/match_entry_footer_img.png",
    "/assets/reports_footer_img.png",
    "/assets/home_footer_img.png",
    "/assets/chat_footer_img.png",
    "/assets/more_footer_icon.png",
  ];
  const history = useHistory();
  const pathname = history.location.pathname;

  // const indexForActiveTab = FooterMenu.findIndex(
  //   (obj) => pathname === obj.href
  // );
  const [activeTab, setActiveTab] = useState(1);
  const [footerClass, setFooterClass] = useState(
    "mid-footer footer-div flex-space-around w-100"
  );
  const handleActiveTab = (index) => {
    switch (index) {
      case 0:
        history.push("/chats");
        setFooterClass("left-footer footer-div flex-space-around w-100");
        break;
      case 1:
        setFooterClass("mid-footer footer-div flex-space-around w-100");
        history.push("/");
        break;
      case 2:
        setFooterClass("right-footer footer-div flex-space-around w-100");
        id ? history.push("/meetings/upcoming") : history.push("/");
        break;
      default:
        setFooterClass("mid-footer footer-div flex-space-around w-100");
    }
    setActiveTab(index);
  };
  useEffect(() => {
    if (pathname == "/meetings/upcoming") {
      setFooterClass("right-footer footer-div flex-space-around w-100");
      setActiveTab(2);
    }
  }, [setActiveTab]);
  return (
    <div className={footerClass}>
      {FooterMenu?.map((item, index) => {
        return (
          <div
            key={index}
            className={
              activeTab === index
                ? "flex-center footer-image-main"
                : "w-20 flex-center"
            }
            onClick={() => handleActiveTab(index)}
          >
            <div className="footer-image-wrap flex-center">
              <img className="footer-image" src={item.img}></img>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default Footer;
