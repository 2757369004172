import {
  IonButton,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonImg,
  IonGrid,
  IonCol,
  IonRow,
  IonCardSubtitle,
  IonIcon,
} from "@ionic/react";
import { calendarOutline } from "ionicons/icons";
import "./styles.css";
import "swiper/swiper-bundle.min.css";
import SwiperCore, { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { Images } from "../../Images";
import { BsArrowRight, BsChevronRight } from "react-icons/bs";
import HomeTable from "./HomeTable";
import moment from "moment";
import { useHistory } from "react-router";

SwiperCore.use([Pagination]);

function Homepage(props) {
  const history = useHistory();
  let userName = localStorage?.getItem("user_name");
  const { allUpcomingMeetings, allCompletedMeetings = [] } = props;
  const handleUpcomingMeetings = () => {
    history.push("/meetings/upcoming");
  };
  const handleJoinRoom = (roomId) => {
    history.push(`/join/${roomId}`);
  };

  const tableColumns = [
    { header: "Admin", field: "admin" },
    { header: "Event Name", field: "eventName" },
    { header: "Status", field: "status" },
  ];

  const tableData =
    allUpcomingMeetings?.length &&
    allUpcomingMeetings?.map((meeting, index) => {
      const createdTimestamp = new Date(meeting.created_time_stamp).getTime();
      const currentTimestamp = new Date().getTime();
      const twentyFourHours = 24 * 60 * 60 * 1000;
      let status;
      if (createdTimestamp > currentTimestamp) {
        status = "Upcoming";
      } else if (currentTimestamp - createdTimestamp <= twentyFourHours) {
        status = "Join";
      } else if (currentTimestamp - createdTimestamp > twentyFourHours) {
        status = "Completed";
      }
      return {
        admin: (
          <div className="d-flex flex-column">
            {meeting?.createdBy || ""}
            <br />
            {moment(meeting?.created_time_stamp).format("DD-MM-YYYY")}
            <br />
            {moment(meeting?.created_time_stamp).format("HH:mm a")}
          </div>
        ),
        eventName: (
          <div className="text-center">
            {meeting?.event_name} <br />
            <span className="clr-yellow"> {meeting?.meeting_type}</span>
          </div>
        ),
        status: (
          <div
            className="ml-10"
            onClick={
              status === "Join"
                ? () =>
                    handleJoinRoom(meeting?.meeting_id, meeting?.isvideo_enable)
                : null
            }
          >
            {status}
          </div>
        ),
      };
    });

  const navigate = (path) => {
    history.push(path);
  };

  return (
    <div className="we-2-call-main-section">
      <div className="clr-white pl-10">{userName}</div>
      <div>
        <div className="pl-10">
          <div className="side-head">Featured</div>
          <div className="hr-yellow-line"></div>
        </div>
        <Swiper
          spaceBetween={0}
          slidesPerView={1}
          autoplay={{ delay: 1000 }}
          pagination={{ clickable: true }}
          className="we-2-call-slider"
        >
          <SwiperSlide>
            <IonCard className="d-flex main-home-banner ion-align-items-center">
              <IonCardHeader>
                <IonCardTitle className="we-2-call-description">
                  <div>Connect with</div>
                  <div className="bold-text we-2-call-text">
                    Confidence & Secure
                  </div>
                </IonCardTitle>
                <IonButton
                  className="we-2-call-button"
                  onClick={() => handleUpcomingMeetings()}
                >
                  We2Call
                </IonButton>
              </IonCardHeader>
              <IonImg src={Images.HomeMainBanner} />
            </IonCard>
          </SwiperSlide>
          <SwiperSlide>
            <IonCard className="d-flex main-home-banner ion-align-items-center">
              <IonCardHeader>
                <IonCardTitle className="we-2-call-description">
                  <div>Connect with</div>
                  <div className="bold-text we-2-call-text">
                    Confidence & Secure
                  </div>
                </IonCardTitle>
                <IonButton
                  className="we-2-call-button"
                  onClick={() => history.push("/meetings")}
                >
                  We2Call
                </IonButton>
              </IonCardHeader>
              <IonImg src={Images.HomeMainBanner} />
            </IonCard>
          </SwiperSlide>
        </Swiper>
      </div>
      <IonGrid>
        <div className="pl-10 flex-jc-sb pr-10">
          <div>
            <div className="side-head">Live/Upcoming Meetings</div>
            <div className="hr-yellow-line"></div>
          </div>
          <div
            className="flex-jc-c see-all-button"
            onClick={() => navigate("/meetings/upcoming")}
          >
            See All
            <BsChevronRight className="pl-5 font-16 clr-yellow" />
          </div>
        </div>
        <IonRow className="flex-jc-sb mt-5">
          <IonCol size="4.5" className="d-flex">
            <div
              className="upcoming-meetings-container"
              onClick={() => handleUpcomingMeetings()}
            >
              <div className="d-flex upcoming-meetings-date">
                <IonIcon
                  icon={calendarOutline}
                  className="date-icon"
                  slot="start"
                />
                <div>
                  {moment(allUpcomingMeetings[0]?.date).format("DD-MM-YYYY")}
                </div>
              </div>
              <div className="d-flex ion-margin-top">
                <IonImg
                  src={Images.HeadPhone}
                  className="upcoming-meetings-image"
                />
                <div>
                  <div className="d-flex upcoming-meetings">
                    <div className="upcoming-meetings">Upcoming Meetings</div>
                    <div className="bold-text upcoming-meetings-count">
                      {allUpcomingMeetings.length}
                    </div>
                  </div>
                  <div className="upcoming-meeting-time upcoming-meeting-position">
                    Time -
                    {moment(allUpcomingMeetings[0]?.given_time_stamp).format(
                      "h:mm A"
                    )}
                  </div>
                </div>
              </div>
            </div>
          </IonCol>
          <IonCol size="7">
            <div className="w-100 flex-jc-sb border-buttom text-clr">
              {tableColumns.map((column, index) => (
                <div key={index}>
                  <div>{column.header}</div>
                </div>
              ))}
            </div>
            <div className="scroll-table">
              <HomeTable data={tableData || []} columns={tableColumns} />
            </div>
          </IonCol>
        </IonRow>
      </IonGrid>
      <div>
        <div className="pl-10">
          <div className="side-head">Safe & Secure Calling</div>
          <div className="hr-yellow-line"></div>
        </div>
        <IonCard
          className="d-flex we-2-support-call-container"
          onClick={() => handleUpcomingMeetings()}
        >
          <IonCardHeader>
            <IonCardTitle className="we-2-call-heading">We2Call</IonCardTitle>
            <IonCardSubtitle className="support-call-text">
              Get Together with group call
            </IonCardSubtitle>
          </IonCardHeader>
          <IonImg
            src={Images?.MessageIcon}
            alt="message"
            className="message-icon"
          />
          <IonImg src={Images.Star1} alt="star" className="big-star" />
          <IonImg src={Images.Star2} alt="star" className="medium-star" />
          <IonImg src={Images.Star2} alt="star" className="small-star" />
          <IonImg
            src={Images.CallMen}
            alt="call-men"
            className="callmen-image"
          />
        </IonCard>
      </div>
      <IonGrid>
        <IonRow className="d-flex">
          <IonCol size="6" className="d-flex h-14vh">
            <div
              className="completed-meetings-container h-14vh"
              onClick={() => navigate(`/meetings/${"complted"}`)}
            >
              <div className="d-flex mb-10">
                <IonImg
                  src={Images.CallIcon}
                  className="completed-meetings-image"
                />
                <div className="d-flex completed-meetings-container-card">
                  <div className="upcoming-meetings">
                    Last Completed Meetings
                  </div>
                  <div className="bold-text upcoming-meetings-count">
                    {allCompletedMeetings?.length}
                  </div>
                </div>
              </div>
              <div className="d-flex completed-meetings-duration completed-meetings-position">
                <div>Duration</div>
                <div>0 min</div>
              </div>
            </div>
          </IonCol>
          <IonCol
            size="6"
            className="d-flex h-14vh"
            onClick={() => navigate("/tours-and-tournament")}
          >
            <div className="tours-container h-14vh w-100">
              <div className="fw-600 font-14 white-text">
                Tours &<br /> Tournaments
              </div>
              <div className="font-10">
                Enjoy unlimited fun and
                <br /> Win more money.
              </div>
              <div className="r-arrow">
                <BsArrowRight />
              </div>
            </div>
          </IonCol>
        </IonRow>
      </IonGrid>
      <IonGrid>
        <IonRow className="d-flex">
          <IonCol className="flex-jc-sb" size="6">
            <div className="world-map-with-points-col flex-jc-sb">
              <div className="wifi-description w-60">
                Wherever you in world Connect anyone, anywhere free & secure
              </div>
              <IonImg src={Images.WifiIcon} className="wifi-image" />
            </div>
          </IonCol>
          <IonCol className="d-flex" size="6">
            <Swiper
              className="website-slider"
              spaceBetween={0}
              slidesPerView={1}
              autoplay={{ delay: 1000 }}
              pagination={{ clickable: true }}
            >
              <SwiperSlide>
                <div className="d-flex raavana-container w-100">
                  <div className="ravana-website">texch.com</div>
                  <div className="ravana-web-description">
                    Indian most trusted gaming platforms
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="d-flex raavana-container w-100">
                  <div className="ravana-website">texch.com</div>
                  <div className="ravana-web-description">
                    Indian most trusted gaming platforms
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </IonCol>
        </IonRow>
      </IonGrid>
    </div>
  );
}

export default Homepage;
