import { IonModal, useIonToast } from "@ionic/react";
import { useRef, useState } from "react";
import { IoCloseSharp } from "react-icons/io5";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
import { Images } from "../../Images";
import { RESET_PASSWORD_API } from "../../api/endpoints";
import { call } from "../../api/axios";
import { useLocation } from "react-router";

function ResetPassword(props) {
  const themeChange = localStorage.getItem("theme");
  const location = useLocation();
  const [showOtpInput, setShowOtpInput] = useState(false);
  const [showPassword, setShowPassword] = useState("");
  const [inputData, setInputData] = useState("");
  const [inputType, setInputType] = useState({
    old_password: "password",
    new_password: "password",
    confirm_password: "password",
  });

  const otpInput = [{}, {}, {}, {}, {}, {}];
  const inputRefs = useRef([]);
  const { resetpassword, handleResetPassword } = props;
  const [present] = useIonToast();

  const presentToast = (message, color = "danger") => {
    present({
      message: message,
      duration: 1500,
      position: "middle",
      color,
    });
  };

  const handleShowOtp = async (e) => {
    setShowOtpInput(true);
    e.preventDefault();

    await call(RESET_PASSWORD_API, {
      ...inputData,
      register_id: localStorage.getItem("register_id"),
    })
      .then((resp) => {
        if (resp.data.status == 200) {
          presentToast(resp.data.message, "success");
          window.location?.reload();
        } else {
          presentToast(resp.data.message);
        }
      })
      .catch((err) => {
        presentToast("Network Error");
      });
  };
  const handleShowPassword = (paramsInputType) => {
    setInputType({
      ...inputType,
      [paramsInputType]:
        inputType[paramsInputType] === "password" ? "text" : "password",
    });
    setShowPassword((prev) => !prev);
  };
  const handleOTPChange = (index, event) => {
    const otpvalue = event.target.value;
    const nextIndex = index + 1;

    if (otpvalue && nextIndex < inputRefs.current.length) {
      inputRefs.current[nextIndex].focus();
    }
  };
  const onInputChange = (e) => {
    setInputData({ ...inputData, [e.target.name]: e.target.value });
  };

  const { old_password, new_password, confirm_password } = inputData;
  return (
    <IonModal id="signup-modal" isOpen={resetpassword}>
      <div className="login-bg p-10">
        
        <div className="w-100 d-flex-jc-end">
          <IoCloseSharp className="close-icon" onClick={handleResetPassword} />
        </div>
        <div className="flex-jc-sb mt-10">
          <div className="signup-label">Reset Password</div>
          <img
            className="custom-w2c-img"
            src={
              themeChange === "dark-mode"
                ? Images?.We2callDark
                : Images?.We2CallLight
            }
            alt="We2Call_Logo"
          />
        </div>
        <form>
          <div className="flex-jc-sb email-input mt-20 w-100 font-14">
            <input
              placeholder="Enter Old Password"
              type={inputType["old_password"]}
              value={old_password}
              name="old_password"
              onChange={onInputChange}
              className="email-input-tag font-14"
            />
            <i onClick={() => handleShowPassword("old_password")}>
              {inputType["old_password"] == "text" ? (
                <AiFillEye className="close-icon mr-10" />
              ) : (
                <AiFillEyeInvisible className="close-icon mr-10" />
              )}
            </i>
          </div>
          <div className="flex-jc-sb email-input mt-20 w-100 font-14">
            <input
              placeholder="Enter New Password"
              type={inputType["new_password"]}
              value={new_password}
              name="new_password"
              onChange={onInputChange}
              className="email-input-tag font-14"
            />
            <i onClick={() => handleShowPassword("new_password")}>
              {inputType["new_password"] == "text" ? (
                <AiFillEye className="close-icon mr-10" />
              ) : (
                <AiFillEyeInvisible className="close-icon mr-10" />
              )}
            </i>
          </div>
          <div className="flex-jc-sb email-input mt-20 w-100 font-14">
            <input
              placeholder="Confirm New Password"
              type={inputType["confirm_password"]}
              value={confirm_password}
              name="confirm_password"
              onChange={onInputChange}
              className="email-input-tag font-14"
            />
            <i onClick={() => handleShowPassword("confirm_password")}>
              {inputType["confirm_password"] == "text" ? (
                <AiFillEye className="close-icon mr-10" />
              ) : (
                <AiFillEyeInvisible className="close-icon mr-10" />
              )}
            </i>
          </div>
          <button
            className={
              showOtpInput
                ? "otp-button custom-box-shadow clr-grn mt-20 font-14"
                : "otp-button custom-box-shadow mt-20 clr-grn font-14"
            }
            onClick={handleShowOtp}
          >
            Reset Password
          </button>
          {/* {showOtpInput && (
          <center>
            <hr />
            <div className="flex mt-10">
              {otpInput.map((digit, index) => (
                <input
                  className="flex-jc-c otp-inputs font-14"
                  key={index}
                  type="number"
                  inputMode="numeric"
                  // placeholder="-"
                  // value={value}
                  ref={(el) => (inputRefs.current[index] = el)}
                  onChange={(event) => handleOTPChange(index, event)}
                  length={1}
                />
              ))}
            </div>
            <button className="otp-button custom-box-shadow mt-20 clr-grn font-14" type="submit">
              Submit
            </button>
          </center>
        )} */}
        </form>
      </div>
    </IonModal>
  );
}

export default ResetPassword;
