import { useState } from "react";
import { IonCol, IonImg, IonPage, IonRow } from "@ionic/react";
import "./styles.css";
import {
  MdMicNone,
  MdMicOff,
  MdOutlineFeaturedVideo,
  MdVideocamOff,
  MdArrowBackIosNew,
} from "react-icons/md";
import { FiVideo } from "react-icons/fi";
import { IoChatbubbleEllipsesOutline } from "react-icons/io5";
import { ImPhoneHangUp } from "react-icons/im";
import { HiDotsHorizontal } from "react-icons/hi";
import calling from "./asssets/calling.png";
import { useHistory } from "react-router";
import CallingMain from "./CallingMain";
import UserCallingScreen from "./UserCallingScreen";
import ChatScreen from "./ChatScreen";

function WaitingJoinScreen() {
  const history = useHistory();
  const [isMicOn, setIsMicOn] = useState(true);
  const [isVideoOn, setIsVideoOn] = useState(true);
  const toggleMic = () => {
    setIsMicOn((prevState) => !prevState);
  };
  const toggleVideo = () => {
    setIsVideoOn((prevState) => !prevState);
  };
  const [isOnCall, setIsOnCall] = useState(true);
  const handleOnCallEvent = () => {
    setIsOnCall((prev) => !prev);
  };
  const [chatPopup, setChatPopup] = useState(false);
  const handleChatPopupOpen = () => {
    setChatPopup(true);
  };
  return (
    <div className="call-bg">
      <div className="header-10vh">
        <IonRow className="p-10">
          <IonCol
            size="1"
            className="flex-center call-blue-bg text-center br-5"
            onClick={() => history.push("/calling")}
          >
            <MdArrowBackIosNew className="d-flex font-18" />
          </IonCol>
          <IonCol size="1"></IonCol>
          <IonCol
            size="10"
            className="call-blue-bg text-center br-5 font-16 clr-white p-5"
          >
            <div className="d-flex flex-row w-100 justify-content-between p-3">
              <div className="d-flex">
                <span className="mr-5">17-04-2023,</span>
                <span className="ml-5">01:28:00 AM</span>
              </div>
              <div onClick={() => handleOnCallEvent()}>Family Meeting</div>
            </div>
          </IonCol>
        </IonRow>
      </div>
      <div
        className="d-flex justify-content-center align-items-center flex-column"
        style={{ height: "68vh" }}
      >
        {isOnCall ? (
          <>
            <IonImg src={calling} className="h-22" />
            <div className="clr-white font-18 fw-700 mt-10">
              Waiting To Join ...
            </div>
          </>
        ) : (
          <UserCallingScreen />
        )}
      </div>

      <div className="footer-bg h-14vh d-flex justify-content-center align-items-center p-10 border-top-radius-50p">
        <IonRow className="w-100 justify-content-between d-flex">
          <IonCol
            size="1.5"
            className="br-5 call-bg p-5 text-center"
            onClick={toggleMic}
          >
            {isMicOn ? (
              <MdMicNone className="font-30 clr-white" />
            ) : (
              <MdMicOff className="font-30 clr-white" />
            )}
          </IonCol>
          <IonCol
            size="1.5"
            className="br-5 call-bg p-5 text-center"
            onClick={toggleVideo}
          >
            {isVideoOn ? (
              <FiVideo className="font-30 clr-white" />
            ) : (
              <MdVideocamOff className="font-30 clr-white" />
            )}
          </IonCol>
          <IonCol size="1.5" className="br-5 call-bg p-5 text-center">
            <MdOutlineFeaturedVideo className="font-30 clr-white" />
          </IonCol>

          <IonCol
            size="1.5"
            className="br-5 call-bg p-5 text-center"
            onClick={() => handleChatPopupOpen()}
          >
            <IoChatbubbleEllipsesOutline className="font-30 clr-white" />
          </IonCol>
          <IonCol size="1.5" className="br-5 call-bg p-5 text-center">
            <HiDotsHorizontal className="font-30 clr-white" />
          </IonCol>
          <IonCol size="3" className="red-bg p-5 text-center br-5">
            <ImPhoneHangUp className="font-30 clr-white" />
          </IonCol>
        </IonRow>
      </div>
      <ChatScreen chatPopup={chatPopup} setChatPopup={setChatPopup} />
    </div>
  );
}

export default WaitingJoinScreen;
