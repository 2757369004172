import { useHistory } from "react-router";
import { IonCol, IonRow } from "@ionic/react";
import { useEffect, useState } from "react";


function UpcomingMeetings(props) {
  const history = useHistory();
  const { allUpcomingMeetings = [] } = props;

  const handleJoinRoom = (roomId, isVideoEnabled) => {
    // history.push(`/group-call/${roomId}`);
    history.push(`/join/${roomId}`);
  };
  useEffect(() => {
    console.log("allUpcomingMeetings", allUpcomingMeetings);
  }, []);

  return (
    <div className="meetings-container w-92">
      <div className="meetings-heading mt-10 mb-10">Live/Upcoming Meetings</div>
      <div>
        <IonRow>
          <IonCol size="4">
            <div className="meetings-button custom-box-shadow flex-jc-c w-100">Date & Time</div>
          </IonCol>
          <IonCol size="4">
            <div className="meetings-button custom-box-shadow flex-jc-c  w-100">Meeting Title</div>
          </IonCol>
          <IonCol size="4">
            <div className="meetings-button custom-box-shadow flex-jc-c w-100">Status</div>
          </IonCol>
        </IonRow>
        {allUpcomingMeetings &&
          allUpcomingMeetings
            ?.sort((a, b) => a.created_time_stamp - b.created_time_stamp)
            ?.map((meeting, index) => {
              const createdTimestamp = new Date(meeting.created_time_stamp).getTime();
              const currentTimestamp = new Date().getTime();
              const twentyFourHours = 24 * 60 * 60 * 1000;

              let status;
              if (createdTimestamp > currentTimestamp) {
                status = "upcoming";
              } else if (currentTimestamp - createdTimestamp <= twentyFourHours) {
                status = "join";
              } else if (currentTimestamp - createdTimestamp > twentyFourHours) {
                status = "completed";
              }

              return (
                <IonRow className="justify-content-between d-flex custom-box-shadow mt-10 align-items-center" key={index}>
                  <IonCol size="4">
                    <div className="flex-jc-se flex-column custom-box-shadow meetings-date-time-div w-100">
                      <div className="meetings-date fw-600">{meeting?.date}</div>
                      <div className="meetings-time">{meeting?.time}</div>
                    </div>
                  </IonCol>
                  <IonCol size="4">
                    <div className="meetings-time w-100 padding-tb">
                      {meeting?.event_name}
                      <br />
                      {meeting?.match_name}
                      <br />
                      <span className="clr-yellow"> {meeting?.meeting_type}</span>
                    </div>
                  </IonCol>
                  <IonCol size="4">
                    <div className="w-100 d-flex justify-content-center">
                      {status === "upcoming" && <div className="meetings-time w-60 text-center border-grey clr-orng br-10 p-5">UPCOMING</div>}
                      {status === "join" && (
                        <div className="meetings-time meeting-join-text w-40 border-grey br-10 p-5" onClick={() => handleJoinRoom(meeting?.meeting_id, meeting)}>
                          JOIN
                        </div>
                      )}
                      {status === "completed" && <div className="meetings-time w-60 text-center border-grey clr-red br-10 p-5">COMPLETED</div>}
                    </div>
                    {/* <div className="w-100 d-flex justify-content-center">
                    <div
                      className="meetings-time meeting-join-text w-40 border-grey"
                      onClick={() =>
                        handleJoinRoom(meeting?.meeting_id, meeting)
                      }
                    >
                      JOIN
                    </div>
                  </div> */}
                  </IonCol>
                </IonRow>
              );
            })}
      </div>
    </div>
  );
}

export default UpcomingMeetings;
