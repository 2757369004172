import { useEffect, useState } from "react";
import Footer from "../../components/footer/Footer";
import EditProfileHeader from "./EditProfileHeader";
import { IonAvatar, IonImg } from "@ionic/react";
import "./styles.css";
import { Images, UpdatedBaseUrl } from "../../Images";
import { call, Signed } from "../../api/axios";
import { GENERATE_SIGNED_URL, GET_USER_INFO, UPDATE_PROFILE } from "../../api/endpoints";
import ThankYouPopup from "../../pages/popup/ThankYouPopup";

function EditProfile() {
  const register_id = localStorage.getItem("register_id");
  const [signedUrl, setSignedUrl] = useState("");
  const [profileImage, setProfileImage] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);
  const [openThankyouPopup, setOpenThankYouPopup] = useState(false);

  const [errMsg, setErrMsg] = useState();

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    mobile_no: "",
    user_name: "",
    profile_image: "",
  });
  // const getProfile = async (id) => {
  //   try {
  //     const res = await call(GET_USER_INFO, { register_id });
  //     const userProfileData = res?.data?.data;
  //     const profileImage = userProfileData?.profile_image || "";
  //     if (profileImage) {
  //       localStorage.setItem("profileImage", profileImage);
  //     }
  //     setFormData({
  //       first_name: userProfileData?.first_name || "",
  //       last_name: userProfileData?.last_name || "",
  //       email: userProfileData?.email || "",
  //       mobile_no: userProfileData?.mobile_no || "",
  //       user_name: userProfileData?.user_name || "",
  //       profileImage: userProfileData?.profile_image || "",
  //     });
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };
  // useEffect(() => {
  //   getProfile();
  // }, []);
  const generateSignedUrl = async () => {
    setIsProcessing(true);
    await Signed(GENERATE_SIGNED_URL, {
      register_id,
      event_type: "user_profile_image",
      folder_name: "profile-images",
    })
      .then((res) => {
        setIsProcessing(false);
        const url = res?.data?.data?.result?.signed_url;
        setSignedUrl(url);
      })
      .catch((err) => {
        setIsProcessing(false);
        console.log(err);
      });
  };
  const validateForm = () => {
    let isValid = true;
    const newErrors = {};
    if (!formData?.first_name || !formData?.last_name || !formData?.user_name) {
      newErrors.errorMessage = "Please enter required fields";
      isValid = false;
    }
    if (!/^\S+@gmail\.com$/.test(formData.email)) {
      newErrors.email = "Please enter a valid Gmail address";
      isValid = false;
    }
    if (!/^\d{10}$/.test(formData.mobile_no)) {
      newErrors.mobile_no = "Mobile number must be 10 digits";
      isValid = false;
    }
    setErrMsg(newErrors);
    return isValid;
  };

  const closeThankYouPopup = () => {
    setOpenThankYouPopup(false);
  };


  // const handleUploadFileSelect = (e) => {
  //   const file = e.target.files[0];
  //   setProfileImage(file);
  //   generateSignedUrl();
  // };


  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfileImage(reader.result);
        generateSignedUrl(register_id);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleChange = (e) => {
    if (e.target.name === "mobile_no" && e.target.value.length > 10) {
      return;
    }
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const handleSubmit = async () => {
    if (!validateForm()) {
      return;
    }
    setIsProcessing(true);
    if (signedUrl && profileImage) {
      await fetch(signedUrl, {
        method: "PUT",
        body: profileImage,
        headers: {
          "Content-Type": "image/jpeg",
          "cache-control": "public, max-age=0",
        },
      })
        .then((res) => {
          setIsProcessing(false);
          console.log(res);
        })
        .catch((err) => console.log(err));
    }

    await call(UPDATE_PROFILE, {
      register_id,
      user_name: formData.user_name,
      first_name: formData.first_name,
      last_name: formData.last_name,
      email: formData.email,
      mobile_no: formData.mobile_no,
      whatsapp_no: formData?.whatsapp_no,
      skype_id: formData?.skype_id,
      // profile_image: `${ImageBaseUrl2}${folderName}/${register_id}.png`,
      profile_image: profileImage,
    })
      .then(async (res) => {
        setIsProcessing(false);
        if (res?.data?.statusCode === 200) {
          setIsProcessing(false);
          setOpenThankYouPopup(true);
          window.location.reload();
          setTimeout(() => {
            setOpenThankYouPopup(false);
          }, 1000);
        }
      })
      .catch((err) => {
        setIsProcessing(false);
        console.log(err);
      });
  };
  useEffect(() => {
    localStorage.setItem("user_name", formData.user_name || "");
  }, [formData]);
  const getUserProfile = async () => {
    await call(GET_USER_INFO, { register_id })
      .then((res) => {
        if (res?.data?.status === 200) {
          console.log("register_id", register_id);
          console.log("getUserProfile", res);
          setFormData({ ...res?.data?.data });
          setProfileImage(res?.data?.data.profile_image);
        }
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getUserProfile();
  }, []);
  // const closeThankYouPopup = () => {
  //   setOpenThankYouPopup(false);
  // };

  return (
    <div className="main-container">
      <EditProfileHeader />
      <hr className="horizantal-line" />
      <div className="support-home edit-profile-container">
        <div>
          <IonAvatar className="edit-profile-img">
            {/* <IonImg src={formData?.profile_image} alt="profil-icon" /> */}
            <IonImg src={profileImage} alt="profil-icon" />
          </IonAvatar>
        </div>
        <div className="flex-jc-c edit-profile-icon">
          <label htmlFor="upload-button">
            <img className="edit-icon-black" src={Images.EditIcon} alt="Edit-Icon" />
          </label>
          <input
            type="file"
            id="upload-button"
            style={{ display: "none" }}
            onChange={handleFileChange}
            // onChange={(e) => {
            //   setProfileImage(e?.target?.files[0]);
            //   generateSignedUrl(register_id);
            //   const reader = new FileReader();
            //   reader.onloadend = () => {
            //     setFormData.profile_image(reader.result);
            //     // setProfileImage(reader.result);
            //   };
            //   if (e?.target?.files[0]) {
            //     reader.readAsDataURL(e.target.files[0]);
            //   }
            // }}
          />
        </div>
        <div>
          <input className="w-100 mt-20 font-14 edit-profile-input-properties custom-box-shadow outline-none" placeholder="Enter Name" type="text" name="user_name" value={formData?.user_name || ""} onChange={handleChange} />
          <span className="err-msg">{errMsg?.errorMessage}</span>
          <input className="w-100 mt-20 font-14 edit-profile-input-properties custom-box-shadow outline-none" placeholder="Enter First Name" value={formData?.first_name || ""} type="text" onChange={handleChange} name="first_name" />
          <span className="err-msg">{errMsg?.errorMessage}</span>
          <input className="w-100 mt-20 font-14 edit-profile-input-properties custom-box-shadow outline-none" placeholder="Enter Last Name" value={formData?.last_name || ""} type="text" onChange={handleChange} name="last_name" />
          <span className="err-msg">{errMsg?.errorMessage}</span>
          <input className="w-100 mt-20 font-14 edit-profile-input-properties custom-box-shadow outline-none" placeholder="Email ID" type="email" value={formData?.email || ""} onChange={handleChange} name="email" />
          <span className="err-msg">{errMsg?.email}</span>
          {/* <input
            className="w-100 mt-20 font-14 edit-profile-input-properties custom-box-shadow outline-none"
            placeholder="Skype ID"
            value={formData?.skype_id || ""}
            type="text"
            onChange={handleChange}
            name="skype_id"
          />
          <span className="err-msg">{errMsg?.skype_id}</span> */}
          {/* <input
            className="w-100 mt-20 font-14 edit-profile-input-properties custom-box-shadow outline-none"
            placeholder="Whatsapp Number"
            value={formData?.whatsapp_no || ""}
            onChange={handleChange}
            type="number"
            name="whatsapp_no"
          />
          <span className="err-msg">{errMsg?.whatsapp_no}</span> */}
          <input className="w-100 mt-20 font-14 edit-profile-input-properties custom-box-shadow outline-none" placeholder="Phone Number" value={formData?.mobile_no || ""} type="number" onChange={handleChange} name="mobile_no" />
          <span className="err-msg">{errMsg?.mobile_no}</span>
        </div>
        <button className="w-100 mt-20 font-14 verify-phn-container-btn" onClick={handleSubmit} disabled={isProcessing}>
          {isProcessing ? "Processing..." : "Update Profile"}
        </button>
      </div>
      <ThankYouPopup openThankyouPopup={openThankyouPopup} setOpenThankYouPopup={setOpenThankYouPopup} onClose={closeThankYouPopup} />
      <Footer />
    </div>
  );
}

export default EditProfile;
